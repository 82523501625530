import React, { Component } from 'react';
import { Async } from 'react-select';
import { Router } from 'react-router';
// import {
//     Badge, Button, ButtonGroup, Card, CardColumns, CardDeck, CardImg, CardText, CardBlock,
//     CardTitle, CardSubtitle, Col, Container, Form, FormGroup, FormText, Form.Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row
// } from 'reactstrap';
import { Button, Form, Grid, Header, Modal } from 'semantic-ui-react'
import {
    Link,
    Route
} from 'react-router-dom'
// import Slider, { Range } from 'rc-slider';
import { CSSTransitionGroup } from 'react-transition-group'
import PubSub from 'pubsub-js';
import queryString from 'query-string';
import Select from 'react-select';
// import 'react-select/dist/react-select.css';
// import 'rc-slider/assets/index.css';
import * as http from '../http.js';
import * as store from '../store.js';

class ProfileEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            profile: { company: {} },
            show_password_confirmation: false,
            profiles: [{ 'key': 'admin', 'label': 'Administrador' }, { 'key': 'user', 'label': 'Usuário' }],
            modal: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.close = this.close.bind(this);
        this.changeRole = this.changeRole.bind(this);
        this.editUser = this.editUser.bind(this);
    }
    toggle() {
        this.setState({ modal: !this.state.modal });
    }
    close() {
        this.setState({ modal: false });
    }
    editUser(profile) {
        if (profile) {
            http.get_api('/api/profile/' + profile.data.user._id, (ret) => {
                this.setState({ modal: true, profile: ret.data.profile, user: ret.data.user });
            });
        } else {
            this.setState({ modal: true, profile: {}, user: {} });
        }
        // this.setState({ modal: !this.state.modal });
    }
    handleElementChange(val, el, key) {
        el = this.state[el];
        el[key] = val;
        this.setState({ el });
        if (key === 'password' && val.length > 0) {
            this.setState({ show_password_confirmation: true });
        }
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let names = target.name.split('.');
        var prop = this.state;
        var el = this.state[names[0]];
        for (var i in names) {
            if (typeof prop[names[i]] != 'object') break;
            prop = prop[names[i]];
        }
        prop[names[names.length - 1]] = value;
        this.setState({
            [names[0]]: el
        });
    }
    changeRole(ev) {
        let profile = this.state.profile;
        profile.role = ev.key;
        this.setState({ profile })
    }
    handleSubmit(event) {
        event.preventDefault();
        if (this.state.user.password == '') delete this.state.user['password'];
        if (this.state.user.passwordConfirmation == '') delete this.state.user['passwordConfirmation'];
        http.post_api('/api/profile', { profile: this.state.profile, user: this.state.user }, (ret) => {
            PubSub.publish('profile_updated');
            if(this.props.update){
                this.props.update();
            }
            // store.set_object('profile', ret.data.profile);
            // this.props.history.push('/tasks');
            this.setState({ modal: false });
        });
    }
    render() {
        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <Modal basic open={this.state.modal} closeOnDimmerClick={false} onClose={this.toggle} size={'small'}>
                        <Modal.Header>
                            <Header>Usuário</Header>
                        </Modal.Header>
                        <Modal.Content>
                            <Grid columns={3}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Nome</label>
                                            <Form.Input type="text" name="user.first_name" value={this.state.user.first_name} id="user.first_name" onChange={(e) => this.handleElementChange(e.target.value, 'user', 'first_name')} />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Sobrenome</label>
                                            <Form.Input type="text" name="user.last_name" value={this.state.user.last_name} id="user.last_name" onChange={(e) => this.handleElementChange(e.target.value, 'user', 'last_name')} />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Email</label>
                                            <Form.Input type="text" name="user.email" value={this.state.user.email} id="user.email" onChange={(e) => this.handleElementChange(e.target.value, 'user', 'email')} />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    {store.get_object('profile').role === 'admin' &&
                                        <Grid.Column>
                                            <Form.Field>
                                                <label>Perfil</label>
                                                <Select
                                                    name="form-field-name"
                                                    value={this.state.profile.role}
                                                    valueKey='key'
                                                    options={this.state.profiles}
                                                    onChange={this.changeRole}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    }
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Senha</label>
                                            <Form.Input type="password" autoComplete={false} name="user.password" value={this.state.user.password} id="user.password" onChange={(e) => this.handleElementChange(e.target.value, 'user', 'password')} />
                                        </Form.Field>
                                    </Grid.Column>
                                    {this.state.show_password_confirmation &&
                                        <Grid.Column>
                                            <Form.Field>
                                                <label>Confirme sua Senha</label>
                                                <Form.Input type="password" autoComplete={false} name="user.passwordConfirmation" value={this.state.user.confirmation} id="user.passwordConfirmation" onChange={(e) => this.handleElementChange(e.target.value, 'user', 'passwordConfirmation')} />
                                            </Form.Field>
                                        </Grid.Column>
                                    }
                                </Grid.Row>
                            </Grid>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color="orange" inverted size="mini" onClick={this.toggle}>Cancelar</Button>
                            <Button type="submit" inverted color="green" onClick={this.handleSubmit}>Salvar</Button>
                        </Modal.Actions>
                    </Modal>
                </Form>
            </div>
        )
    }
}

export default ProfileEdit;
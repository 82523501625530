export function get(key){
    return window.localStorage.getItem(key);
}

export function get_object(key){
    let obj = window.localStorage.getItem(key);
    if(obj) obj = JSON.parse(obj);
    return obj;
}

export function set(key,value){
    window.localStorage.setItem(key,value);
}

export function set_object(key,value){
    window.localStorage.setItem(key,JSON.stringify(value));
}

export function remove(key){
    window.localStorage.removeItem(key);
}
import React, { Component } from 'react';
import { Button, Divider, Form, Grid, Icon, Input, Label, Menu as MenuS, Modal, Popup, Segment } from 'semantic-ui-react'
import * as store from '../../store.js';

class Menu extends Component {
    static defaultProps = {
        updateComponent: () => { },
        componentGetData: () => { },
        getProject: () => { },
        getProfile: () => { },
        getTags: () => { }
    }
    constructor(props) {
        super(props);
        this.state = {
            modal: props.display,
            items: [],
            profile: store.get_object('profile'),
            content_tags: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.addMenuItem = this.addMenuItem.bind(this);
        this.addMenuSubItem = this.addMenuSubItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.removeSubItem = this.removeSubItem.bind(this);
    }
    componentDidMount() {
        let val = this.props.componentGetData({ id: this.props.id });
        let tags = store.get_object('content_tags');
        if (!val) val = { content: [] };
        if (!val.content) val = { content: val, name: 'Menu' };
        this.setState({ items: val.content, content_tags: tags })
    }
    handleChange(value, el, key) {
        // this.setState({ text: value });
        el[key] = value;
        this.setState({ items: this.state.items });
        this.props.updateComponent({ id: this.props.id, name: 'Menu', value: this.state.items });
    }
    addMenuItem() {
        if (!this.state.items) this.state.items = [];
        if (!Array.isArray(this.state.items))  this.state.items = [];
        this.state.items.push({ name: 'Novo' });
        this.setState({ items: this.state.items });
        this.props.updateComponent({ id: this.props.id, name: 'Menu', value: this.state.items });
    }
    addMenuSubItem(item) {
        if (!item.subs) item.subs = [];
        item.subs.push({ name: 'Novo' });
        // this.state.items.push({name:'Novo'});
        this.setState({ items: this.state.items });
        this.props.updateComponent({ id: this.props.id, name: 'Menu', value: this.state.items });
    }
    removeItem(idx) {
        this.state.items.splice(idx, 1);
        this.setState({ items: this.state.items });
        this.props.updateComponent({ id: this.props.id, name: 'Menu', value: this.state.items });
    }
    removeSubItem(item,idx) {
        console.log(item.subs)
        item.subs.splice(idx, 1);
        console.log(item.subs)
        this.setState({ items: this.state.items });
        this.props.updateComponent({ id: this.props.id, name: 'Menu', value: this.state.items });
    }
    render() {
        if (this.state.error) {
            return <h1>Caught an error.</h1>
        }
        if (this.state.profile && this.state.profile.role != 'admin') {
            return null;
        }
        if ((this.state.profile && this.state.profile.role != 'admin') && (this.state.content_tags && this.state.content_tags.indexOf('index') >= 0)) { return null }

        let menus_ui = [];
        for (let i = 0; i < this.state.items.length; i++) {
            let item = this.state.items[i];
            let subs = [];
            if (!this.state.items[i].subs) this.state.items[i].subs = [];
            for (let j = 0; j < this.state.items[i].subs.length; j++) {
                let subitem = this.state.items[i].subs[j];
                subs.push(<div style={{borderTop:'1px solid #ccc',paddingTop:'2px',marginBottom:'4px',marginTop:'2px',paddingBottom:'2px'}}>
                    <Button type='button' floated='right' compact onClick={(e) => { this.removeSubItem(item,j) }} size='mini' inverted color='orange' icon='trash'></Button>
                    SubItem do Menu
                <br />
                    <Input label='Nome' labelPosition='right' value={subitem.name} onChange={(e) => { this.handleChange(e.target.value, subitem, 'name') }} />
                    <Input label='Link' labelPosition='right' value={subitem.link} onChange={(e) => { this.handleChange(e.target.value, subitem, 'link') }} />
                </div>);
            }
            menus_ui.push(<Popup key={'menu'+i} size='mini' trigger={<MenuS.Item>{item.name}</MenuS.Item>} on='click'>
                <Button floated='right' type='button' onClick={(e) => { this.removeItem(i) }} size='mini' inverted color='orange' icon='trash'></Button>
                Item do Menu
                <br />
                <Input label='Nome' labelPosition='right' value={item.name} onChange={(e) => { this.handleChange(e.target.value, item, 'name') }} />
                <Input label='Link' labelPosition='right' value={item.link} onChange={(e) => { this.handleChange(e.target.value, item, 'link') }} />
                <hr />
                Submenus <Button type="button" size='mini' icon="plus" onClick={(e) => { this.addMenuSubItem(item) }}></Button>
                <div style={{ maxHeight: '200px', 'overflow': 'auto' }}>
                    {subs}
                </div>
            </Popup>)
        }
        return (
            <Segment>
                <h4>{this.props.name || 'Menu'}</h4>
                <Button type='button' floated='right' compact size='mini' icon='plus' onClick={this.addMenuItem}></Button>
                <MenuS secondary>
                    {menus_ui}
                </MenuS>
            </Segment>
        )
    }
}

export default Menu;
import React, { Component } from 'react';
import {
    Link
} from 'react-router-dom'
// import { Badge, Button, ButtonGroup, Col, Container, Form, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Button, Icon, Input, Label, Search } from 'semantic-ui-react';
import * as http from '../http.js';
import PubSub from 'pubsub-js';

class SelectSingle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            endpoint: null,
            elements: [],
            searchInput: null,
            value:this.props.value||null
        };
        this.getLabel = this.getLabel.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.handleResultSelect = this.handleResultSelect.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.resultRenderer = this.resultRenderer.bind(this);
    }
    componentWillReceiveProps(props) {
        this.setState({
            value:props.value,
            label: props.label,
            endpoint: props.endpoint
        });
    }
    getLabel(el, label) {
        let labels = label.split(',');
        let label_compiled = '';
        for (let i = 0; i < labels.length; i++) {
            let els = labels[i].split('.');
            let ret = el;
            while (els.length > 0) {
                ret = ret[els.shift()];
            }
            label_compiled += ret + ' ';
        }
        return label_compiled;
    }
    resultRenderer(result) {
        return <Label color='blue'>{this.getLabel(result, this.props.label)}</Label>
    }
    handleSearchChange(e, { value }) {
        http.get_api(this.props.endpoint + value, (ret) => {
            this.setState({ elements: ret.data });
            // callback(null,{options:ret.data});
        });
    }
    handleResultSelect(e, { result }) {
        let found = false;
        if (this.props.handleElementChange) {
            this.props.handleElementChange(result);
            return;
        }
        this.setState({ value: result });
    }
    removeItem() {
        this.setState({value:null});
        if (this.props.handleElementChange) {
            this.props.handleElementChange(null);
            return;
        }
        // let els = this.props.items;
        // let pos = els.indexOf(ev);
        // els.splice(pos, 1);
        // this.forceUpdate();
    }
    render() {
        let results = [];
        return (
            <div className='searchInput'>
                {this.state.value &&
                    <Label size='large'>
                        {this.getLabel(this.state.value, this.props.label)}
                        <Button size='mini' inverted color='orange' onClick={(e) => { this.removeItem() }} icon='trash' />
                    </Label>
                }
                {!this.state.value &&
                    <Search input={{ fluid: true }} fluid={true} onResultSelect={this.handleResultSelect} resultRenderer={this.resultRenderer} onSearchChange={this.handleSearchChange} results={this.state.elements} />
                }
            </div>
        );
    }
}


export default SelectSingle;
import React, { Component } from 'react';
import { Router } from 'react-router';
// import { Button, ButtonGroup, Col, Container, Form, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Button, Checkbox, Form, Grid, Header, Icon, Input, Label, Modal, Popup, Segment } from 'semantic-ui-react'
import Dropzone from 'react-dropzone';
import {
    Link,
    Route
} from 'react-router-dom'
import PubSub from 'pubsub-js';
import { Async } from 'react-select';
import queryString from 'query-string';
import moment from 'moment';
import locale_pt from "moment/locale/pt-br";
import * as http from '../../../http.js';
import * as store from '../../../store.js';

moment.locale("pt-br", locale_pt);

class ProjectEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true,
            project: {},
            project_id: props.match.params.id,
            ajax_active: false
        };
        this.toggle = this.toggle.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNakedChange = this.handleNakedChange.bind(this);
        this.handleSSLChange = this.handleSSLChange.bind(this);
        this.onDropKey = this.onDropKey.bind(this);
        this.removeCert = this.removeCert.bind(this);
        moment.locale('pt-BR');

    }
    toggle() {
        if (this.state.modal) {
            let path = '/cms/projects';
            this.props.history.push(path)
        }
        this.setState({ modal: !this.state.modal });
    }
    close() {
        this.setState({ modal: false });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let names = target.name.split('.');
        var prop = this.state;
        var el = this.state[names[0]];
        for (var i in names) {
            if (typeof prop[names[i]] != 'object') break;
            prop = prop[names[i]];
        }
        prop[names[names.length - 1]] = value;
        this.setState({
            [names[0]]: el
        });
    }
    handleNakedChange(checked, project) {
        project.simpleConnector.naked_domain = checked;
        this.setState({ project: this.state.project });
    }
    handleSSLChange(checked, project) {
        project.simpleConnector.ssl = checked;
        this.setState({ project: this.state.project });
    }

    handleElementChange(val, el, key) {
        el = this.state[el];
        el[key] = val;
        this.setState({ el });
    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({ ajax_active: true });
        http.post_api('/api/cms/project', this.state.project, (ret) => {
            this.setState({ ajax_active: false });
            store.set_object('project', ret.data.project);
            this.props.history.push('/cms/projects')
        }, () => { this.setState({ ajax_active: false }) });
    }
    get_data(id) {
        if (id == 'new') {
            this.setState({ project: {} });
        } else {
            http.get_api('/api/cms/project/' + id, (ret) => {
                this.setState({ project: ret.data.project });
            });
        }
    }
    componentWillMount() {
        this.get_data(this.state.project_id);
    }
    onDropCert(files) {
        if (!files || files.length == 0) return;
        http.upload_single_api('/api/cms/files/' + this.state.project_id + '/certificate', files[0], (ret) => {
            if (!this.state.project.simpleConnector.certificates) this.state.project.simpleConnector.certificates = {};
            this.state.project.simpleConnector.certificates['cert'] = ret.data;
            this.setState({ project: this.state.project });
        });
    }
    onDropKey(files) {
        if (!files || files.length == 0) return;
        http.upload_single_api('/api/cms/files/' + this.state.project_id + '/certificate', files[0], (ret) => {
            if (!this.state.project.simpleConnector.certificates) this.state.project.simpleConnector.certificates = {};
            this.state.project.simpleConnector.certificates['key'] = ret.data;
            this.setState({ project: this.state.project });
        });
    }
    removeCert(type) {
        delete this.state.project.simpleConnector.certificates[type];
        this.setState({ route: this.state.route });
    }
    render() {
        if (!this.state.project) return null;
        if (!this.state.project.simpleConnector) this.state.project.simpleConnector = { domain: '', certificates: {} };
        if (!this.state.project.simpleConnector.certificates) this.state.project.simpleConnector.certificates = {};
        // if (this.state.project.dns_enabled) {
        //     if (!this.state.project.simpleConnector) this.state.project.simpleConnector = { domain: '',certificates:{}};
        // }
        return (
            <Segment basic={true}>
                <Header as="h1">
                    {this.state.project._id ? 'Editar' : 'Novo'} Projeto
                </Header>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Field>
                        <label>Nome</label>
                        <Form.Input fluid={true} type="text" name="project.name" value={this.state.project.name} id="project.name" onChange={this.handleInputChange} />
                    </Form.Field>
                    <Segment>
                        <Grid>
                            <Grid.Column width={3} verticalAlign="middle">
                                <Checkbox toggle inverted label='DNS' toggle checked={this.state.project.dns_enabled} onChange={(e, data) => this.handleElementChange(data.checked, 'project', 'dns_enabled')} />
                            </Grid.Column>
                            {this.state.project.dns_enabled &&
                                <Grid.Column width={13} verticalAlign="middle">
                                    <Grid>
                                        <Grid.Column width={4} verticalAlign="middle">
                                            <Checkbox toggle inverted label='Naked (www)' toggle checked={this.state.project.simpleConnector.naked_domain} onChange={(e, data) => this.handleNakedChange(data.checked, this.state.project)} />
                                        </Grid.Column>
                                        <Grid.Column width={12} verticalAlign="middle">
                                            <Input label='DNS' fluid={true} type="text" name="project.simpleConnector.domain" value={this.state.project.simpleConnector.domain} id="project.simpleConnector.domain" onChange={this.handleInputChange} />
                                            <Input label='Alias' fluid={true} type="text" name="project.simpleConnector.alias" value={this.state.project.simpleConnector.alias} id="project.simpleConnector.alias" onChange={this.handleInputChange} />
                                        </Grid.Column>
                                        <Grid.Column width={4} verticalAlign="middle">
                                            <Checkbox toggle inverted label='SSL' toggle checked={this.state.project.simpleConnector.ssl} onChange={(e, data) => this.handleSSLChange(data.checked, this.state.project)} />
                                        </Grid.Column>
                                        {this.state.project.simpleConnector.ssl &&
                                            <Grid.Column width={12}>
                                                {!this.state.project.simpleConnector || !this.state.project.simpleConnector.certificates || !this.state.project.simpleConnector.certificates['key'] &&
                                                    <Segment floated='left' compact={true} padded={false} size='mini'>
                                                        <Dropzone multiple={false} onDrop={this.onDropKey.bind(this)} style={{ height: 'auto' }}>
                                                            <Icon name='key' /> Upload Key File
                                                </Dropzone>
                                                    </Segment>
                                                }
                                                {this.state.project.simpleConnector && this.state.project.simpleConnector.certificates && this.state.project.simpleConnector.certificates['key'] &&
                                                    <Segment floated='left' compact={true} padded={false} size='mini'>
                                                        <Icon name='key' />{this.state.project.simpleConnector.certificates.key.originalname}
                                                        &nbsp;
                                                <Popup
                                                            trigger={<Button type="button" size='mini' color='orange' inverted icon='trash'></Button>}
                                                            content={<Button type="button" color='yellow' inverted content='Confirmar Exclusão' onClick={(e) => { this.removeCert('key') }} />}
                                                            on='click'
                                                            position='top right'
                                                        />
                                                    </Segment>
                                                }
                                                {!this.state.project.simpleConnector || !this.state.project.simpleConnector.certificates || !this.state.project.simpleConnector.certificates['cert'] &&
                                                    <Segment floated='left' compact={true} size='mini'>
                                                        <Dropzone multiple={false} onDrop={this.onDropCert.bind(this)} style={{ height: 'auto' }}>
                                                            Upload Cert File
                                                </Dropzone>
                                                    </Segment>
                                                }
                                                {this.state.project.simpleConnector && this.state.project.simpleConnector.certificates && this.state.project.simpleConnector.certificates['cert'] &&
                                                    <Segment floated='left' compact={true} size='mini'>
                                                        <Icon name='key' />{this.state.project.simpleConnector.certificates.cert.originalname}
                                                        &nbsp;
                                                <Popup
                                                            trigger={<Button type="button" size='mini' color='orange' inverted icon='trash'></Button>}
                                                            content={<Button type="button" color='yellow' inverted content='Confirmar Exclusão' onClick={(e) => { this.removeCert('cert') }} />}
                                                            on='click'
                                                            position='top right'
                                                        />
                                                    </Segment>
                                                }
                                            </Grid.Column>
                                        }
                                    </Grid>
                                </Grid.Column>
                            }
                        </Grid>
                    </Segment>
                    <Segment>
                        <Grid>
                            <Grid.Column width={4} verticalAlign="middle">
                                <Form.Field>
                                    <Checkbox toggle label='GIT' inverted toggle checked={this.state.project.git_enabled} onChange={(e, data) => this.handleElementChange(data.checked, 'project', 'git_enabled')} />
                                </Form.Field>
                            </Grid.Column>
                            {this.state.project.git_enabled &&
                                <Grid.Column width={12}>
                                    <Grid stackable>
                                        <Grid.Column width={16}>
                                            <Input label='URL' fluid={true} type="text" name="project.git_url" value={this.state.project.git_url} id="project.git_url" onChange={this.handleInputChange} />
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Input label='User' fluid={true} type="text" name="project.git_user" value={this.state.project.git_user} id="project.git_user" onChange={this.handleInputChange} />
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Input label='Password' fluid={true} type="password" name="project.git_password" value={this.state.project.git_password} id="project.git_password" onChange={this.handleInputChange} />
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            }
                        </Grid>
                    </Segment>
                    <Segment>
                        <Grid>
                            <Grid.Column width={4} verticalAlign="middle">
                                <Form.Field>
                                    <Checkbox toggle label='CloudFront' inverted toggle checked={this.state.project.cloudfront_enabled} onChange={(e, data) => this.handleElementChange(data.checked, 'project', 'cloudfront_enabled')} />
                                </Form.Field>
                            </Grid.Column>
                            {this.state.project.cloudfront_enabled &&
                                <Grid.Column width={12}>
                                    <Grid stackable>
                                        <Grid.Column width={16}>
                                            <Input label='CouldFront ID' fluid={true} type="text" name="project.cloudfront_id" value={this.state.project.cloudfront_id} id="project.cloudfront_id" onChange={this.handleInputChange} />
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            }
                        </Grid>
                    </Segment>
                    <div className='actionButtons'>
                        <Button onClick={(e)=>{window.history.back()}} disabled={this.state.ajax_active} color="yellow" inverted size="small">Cancel</Button>
                        <Button disabled={this.state.ajax_active} type="submit" inverted color="green" size='large' onClick={this.handleSubmit}>Salvar</Button>
                    </div>
                </Form>
            </Segment>
        );
    }
}

export default ProjectEdit;
import React, { Component } from 'react';
import { Button, Divider, Form, Grid, Icon, Input, Label, Menu as MenuS, Modal, Popup, Segment } from 'semantic-ui-react'

class SubMenu extends Component {
    static defaultProps = {
        updateComponent: () => {
            // console.log('Event Handler');
        },
        componentGetData: () => {
            // console.log('Event Handler');
        },
        getProject: () => {
            // console.log('Event Handler');
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            modal: props.display,
            items:[]
        };
        this.handleChange = this.handleChange.bind(this);
        this.addMenuItem = this.addMenuItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }
    componentDidMount(){
        let val = this.props.componentGetData({ id:this.props.id});
        if (!val) val = {content:[]};
        if(!val.content)val = {content:val,name:'Menu'};
        this.setState({items:val.content})
    }
    handleChange(value,el,key) {
        // this.setState({ text: value });
        el[key] = value;
        this.setState({items:this.state.items});
        this.props.updateComponent({ id:this.props.id, name:'Menu', value: this.state.items });
    }
    addMenuItem(){
        if(!this.state.items)this.state.item=[];
        this.state.items.push({name:'Novo'});
        this.setState({items:this.state.items});
        this.props.updateComponent({ id:this.props.id, name:'Menu', value: this.state.items });
    }
    removeItem(idx){
        this.state.items.splice(idx,1);
        this.setState({items:this.state.items});
        this.props.updateComponent({ id:this.props.id, name:'Menu', value: this.state.items });
    }
    render() {
        if (this.state.error) {
            return <h1>Caught an error.</h1>
        }
        let menus_ui = [];
        for(let i=0; i < this.state.items.length;i++){
            let item = this.state.items[i];
            menus_ui.push(<Popup size='mini' trigger={<MenuS.Item>{item.name}</MenuS.Item>} on='click'>
                <Popup size='mini' trigger={<Button type='button' floated='right' compact size='mini' color='orange' inverted icon='trash'></Button>} on='click'>
                Confirmar a exclusão? <Button type='button' onClick={(e)=>{this.removeItem(i)}} size='mini' inverted color='orange'>Confirmar</Button>
                </Popup>
                Item do Menu
                <br/>
                <Input label='Nome' labelPosition='right' value={item.name} onChange={(e) => {this.handleChange(e.target.value,item,'name')}} />
                <Input label='Link' labelPosition='right' value={item.link} onChange={(e) => {this.handleChange(e.target.value,item,'link')}}/>
            </Popup>)
        }
        return (
            <div>
                <Divider horizontal section>{this.props.name||'SubMenu'}</Divider>
                <Button type='button' floated='right' compact size='mini' icon='plus' onClick={this.addMenuItem}></Button>
                <MenuS secondary>
                {menus_ui}
                </MenuS>
            </div>
        )
    }
}

export default SubMenu;
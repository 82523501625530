import React, { Component } from 'react';
import {
  Link,
  Route
} from 'react-router-dom'
import PubSub from 'pubsub-js';
import { Button, Form, Grid, Segment } from 'semantic-ui-react'

class Home extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  componentWillMount() {
    // PubSub.publish('changeBodyBg','#084B61');
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    return (
      <div className="">
        <Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <h1>
                  TAKE BACK
                <div className='hero2'>
                    &nbsp;YOUR DEADLINES
                </div>
                </h1>
                <div style={{ marginLeft: 22, marginBottom: 20 }}>
                  <small>
                    Gerenciamento Inteligente de Tarefas, Analise de Comportamento e Previsibilidade de Entregas
                <br />Deixei o stress por nossa conta
                </small>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button size='lg' color='info' id="teste-gratis">Teste Grátis</Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                Interface Simples.
            </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }
}

export default Home;
import React, { Component } from 'react';
import { Router } from 'react-router';
// import { Button, ButtonGroup, Col, Container, Form, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Button, Card, Container, Dropdown, Form, Grid, Icon, Input, Label, Modal, Popup, Segment } from 'semantic-ui-react'
import {
    Link,
    Route
} from 'react-router-dom'
import JsxParser from 'react-jsx-parser'
import PubSub from 'pubsub-js';
import { Async } from 'react-select';
import queryString from 'query-string';
import moment from 'moment';
import locale_pt from "moment/locale/pt-br";

import Parser from 'html-react-parser';
import $ from "jquery";

import * as http from '../http.js';
import * as store from '../store.js';
import Carousel from './content/Carousel';
import Articles from './content/Articles';
import EditableContent from './content/EditableContent';
import Tabs from './content/Tabs';
import Menu from './content/Menu';
import SubMenu from './content/SubMenu';
import { ReactDOM } from 'react-dom';
import TemplateEdit from '../pages/cms/projects/templates/template_edit';

moment.locale("pt-br", locale_pt);

class TemplateDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: props.content || {},
            template: props.template,
            components: [],
            project: props.project,
            project_id: props.project_id,
            cmsData: props.cmsData || {},
            tags: props.tags,
            profile: store.get_object('profile')
        }
        this.updateComponent = this.updateComponent.bind(this);
        this.componentGetData = this.componentGetData.bind(this);
        this.getComponents = this.getComponents.bind(this);
        this.getProject = this.getProject.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getTags = this.getTags
        PubSub.subscribe('project_loaded', (ev, project) => {
            this.setState({ project: project });
        });

    }
    componentWillReceiveProps(props) {
        this.setState({ template: props.template });
    }
    componentGetData(data) {
        if (this.state.cmsData[data.id]) {
            if (this.state.cmsData[data.id] && this.state.cmsData[data.id].content_data) {
                return this.state.cmsData[data.id].content_data;
            } else {
                return this.state.cmsData[data.id];
            }
        }
        if (this.state.project.data && this.state.project.data[data.id]) {
            this.state.cmsData[data.id] = this.state.project.data[data.id];
            this.state.cmsData[data.id].content_data = this.state.project.data[data.id].content;
            delete this.state.cmsData[data.id].content;
            if (this.props.updateProjectData) {
                this.props.updateProjectData(this.state.cmsData);
            }

            //alteração caso MENU
            //return this.state.cmsData[data.id].content_data;
            if (this.state.cmsData[data.id].content_data) {
                return this.state.cmsData[data.id].content_data;
            }
            return this.state.project.data[data.id];

        }
        return null;
    }
    getProfile() {
        return this.state.profile;
    }
    updateComponent(data) {
        this.setState((state, props) => {
            // if (!state.cmsData) state.project.data = {};
            state.cmsData[data.id] = { name: data.name, content_data: data.value };
            if (this.props.updateProjectData) {
                this.props.updateProjectData(state.cmsData);
            }
            return null;
        });
    }
    componentWillMount() {
        this.getComponents();
    }
    getComponents() {
        http.get_api('/api/cms/component/' + this.state.project_id + '/list', (ret) => {
            this.setState({ components: ret.data });
        });
    }
    getProject() {
        // if(!this.state.project)this.state.project={};
        return this.state.project_id;
    }
    getTags() {
        let tags = [];
        if (this.state && this.state.tags) {
            for (let i = 0; i < this.state.tags.length; i++) {
                tags.push(this.state.tags[i].name);
            }
        }
        return tags;
    }
    render() {
        let tags = [];
        for (let i = 0; i < this.state.tags.length; i++) {
            tags.push(this.state.tags[i].name);
        }
        let components = { Menu, SubMenu, Articles, Button, Carousel, Tabs, EditableContent };

        let user_edit = false;
        let user_edit_index = false;
        if (tags.indexOf('index') >= 0 && this.state.profile.role != 'admin') {
            user_edit_index = true;
        }
        if (this.state.profile.role != 'admin') {
            user_edit = true;
        }
        if (this.state.template.jsx && this.state.template.jsx.trim() != '') {
            for (let i = 0; i < this.state.components.length; i++) {
                let comp = this.state.components[i];
                this.state.template.jsx = this.state.template.jsx.replace(new RegExp('<' + comp.name, 'g'), '<' + comp.name + ' updateComponent={updateComponent} componentGetData={componentGetData} getProject={getProject} getTags={getTags}');
            }
        }
        return <div>
            {!user_edit &&
                <Segment secondary>
                            <Popup trigger={<Button floated='right' size='mini' icon='close' compact={true} basic />} on='click'>
                                Remover o Template? <Button size='mini' color='orange' compact={true} basic onClick={(e) => { this.props.removeTemplate() }}>Confirmar</Button>
                            </Popup>
                        <small>Template > </small> {this.state.template.name}
                </Segment>
            }
            {this.state.template.jsx &&
                <JsxParser
                    bindings={{
                        getProject: this.getProject,
                        componentGetData: this.componentGetData,
                        updateComponent: this.updateComponent,
                        getProfile: this.getProfile,
                        getTags: this.getTags
                    }}
                    blacklistedTags={['html', 'body', 'script']}
                    showWarnings={false}
                    components={components}
                    jsx={this.state.template.jsx}
                />
            }
            {!this.state.template.jsx &&
                <span>Nenhum componente editável encontrado. Edite o template e adicione.</span>
            }
        </div>
    }
}
export default TemplateDetail;
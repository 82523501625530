import React, { Component } from 'react';

import { Button, Form, Grid, Segment } from 'semantic-ui-react'

import PubSub from 'pubsub-js';
import * as http from '../http.js';
import * as store from '../store.js';
import logo_childhood from '../assets/images/logo_childhood.png';
import logo_fluent from '../assets/images/logo_fluent.png';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                email: '',
                password: ''
            },
            history: props.history
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        PubSub.publish('notification', { test: 'Test' });
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let names = target.name.split('.');
        var prop = this.state;
        var el = this.state[names[0]];
        for (var i in names) {
            if (typeof prop[names[i]] !== 'object') break;
            prop = prop[names[i]];
        }
        prop[names[names.length - 1]] = value;
        this.setState({
            [names[0]]: el
        });
    }
    handleSubmit(event) {
        console.log("state: ", this.state)
        event.preventDefault();
        //get company
        http.post_api('/api/auth', this.state.user, (ret) => {
            console.log("user mail: ", this.state.user.email)
            let email = this.state.user.email
            let domain = email.substring(email.lastIndexOf("@")+1)
            if (domain == "fluent.com.br") {
                store.set_object('isAdmin', true)
            } else {
                store.set('isAdmin', false)
            }
            store.set_object('profile', ret.data.profile);
            store.set('token', ret.data.token);
            window.location.href = '/cms';
            // this.state.history.push('/tasks');
            // this.props.history.push(path);
        },(ret,err) => {
            console.log('Error');
            console.log(err);
        }); 
    }
    render() {
        return (
            <Segment className="fullHeight bg-login">
                <Grid verticalAlign='middle' columns={4} centered className="fullHeight">
                    <Grid.Column>
                        <img src={logo_childhood} />
                    </Grid.Column>
                    <Grid.Column className="content-box">
                        <h1>Login</h1>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Field>
                                <label>Email:</label>
                                <Form.Input type="email" name="user.email" id="user.email" onChange={this.handleInputChange} />
                            </Form.Field>
                            <Form.Field>
                                <label>Senha:</label>
                                <Form.Input type="password" name="user.password" id="user.password" onChange={this.handleInputChange} />
                            </Form.Field>
                            <Button size='large' color='green'>Acessar</Button>
                        </Form>
                    </Grid.Column>
                    <Grid.Column>
                        <img src={logo_fluent} />
                    </Grid.Column>
                </Grid>
            </Segment>
        );
    }
}

export default Login;

import React, { Component } from 'react';
import { Router } from 'react-router';
// import { Button, ButtonGroup, Col, Container, Form, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Button, Divider, Dropdown, Form, Grid, Icon, Input, Label, Modal, Popup, Segment } from 'semantic-ui-react'
import {
    Link,
    Route
} from 'react-router-dom'
import PubSub from 'pubsub-js';
import { Async } from 'react-select';
import queryString from 'query-string';
import AceEditor from 'react-ace';
import 'brace/theme/monokai';
import 'brace/mode/html';

import * as http from '../../../../http.js';
import * as store from '../../../../store.js';

import ComponentCMS from '../../../../components/content/component_cms';

class TemplateEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true,
            template: {},
            project_id: props.match.params.id,
            template_id: props.match.params.template_id,
            ajax_active: false,
            modalComponents: false,
            project: {},
            cms_data: [],
            tags: []
        };
        this.toggle = this.toggle.bind(this);
        this.toggleComponents = this.toggleComponents.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitClose = this.handleSubmitClose.bind(this);
        this.onChangeBody = this.onChangeBody.bind(this);
        this.addComponent = this.addComponent.bind(this);
        this.showAddComponent = this.showAddComponent.bind(this);
        this.toggleFileManager = this.toggleFileManager.bind(this);
        this.getTags = this.getTags.bind(this);
        this.handleChangeTag = this.handleChangeTag.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);

    }
    toggle() {
        if (this.state.modal) {
            let path = '/cms/projects/' + this.props.match.params.id + '/templates';
            this.props.history.push(path)
        }
        this.setState({ modal: !this.state.modal });
    }
    toggleComponents() {
        this.setState({ modalComponents: !this.state.modalComponents });
    }
    close() {
        this.setState({ modal: false });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let names = target.name.split('.');
        var prop = this.state;
        var el = this.state[names[0]];
        for (var i in names) {
            if (typeof prop[names[i]] != 'object') break;
            prop = prop[names[i]];
        }
        prop[names[names.length - 1]] = value;
        this.setState({
            [names[0]]: el
        });
    }
    handleElementChange(val, el, key) {
        el = this.state[el];
        el[key] = val;
        this.setState({ el });
    }
    onChangeBody(data) {
        this.state.template.body = data;
    }
    showAddComponent() {
        this.setState({ modalComponents: true })
    }
    toggleFileManager() {
        this.setState({ modalFiles: !this.state.modalFiles });
    }
    addComponent(html) {
        this.setState({ modalComponents: false })
        this.refs.aceEditor.editor.session.replace(this.refs.aceEditor.editor.selection.getRange(), html);
    }

    handleSubmit(event, cb) {
        event.preventDefault();
        this.setState({ ajax_active: true });
        this.state.template.project = this.state.project_id;
        http.post_api('/api/cms/template/' + this.state.project_id, this.state.template, (ret) => {
            this.state.template._id = ret.data.template._id;
            this.setState({ ajax_active: false, template: this.state.template });
            PubSub.publish('template_updated');
            if (cb) cb();
        }, () => { this.setState({ ajax_active: false }) });
    }
    handleSubmitClose(event) {
        event.preventDefault();
        this.handleSubmit(event, () => {
            this.props.history.push('/cms/projects/' + this.props.match.params.id + '/templates')
        });
        // this.setState({ ajax_active: true });
        // http.post_api('/api/cms/template/'+this.state.project_id, this.state.template, (ret) => {
        //     this.setState({ ajax_active: false });
        //     this.props.history.push('/cms/projects/'+this.props.match.params.id+'/templates')
        // }, () => { this.setState({ ajax_active: false }) });
    }
    getData(id) {
        if (id == 'new') {
            this.setState({ template: {} });
        } else {
            http.get_api('/api/cms/template/' + this.state.project_id + '/' + id, (ret) => {
                let project = store.get_object('current_project');
                if (!ret.data.template.project) ret.data.template.project = project._id;
                this.setState({ template: ret.data.template, project: project });
            });
            http.get_api('/api/cms/content/' + this.state.project_id + '/components', (ret) => {
                this.setState({ cms_data: ret.data.cms_data });
            });
        }
        this.getTags();
    }
    getTags() {
        http.get_api('/api/tags', (ret) => {
            let tags_options = [];
            for (let i = 0; i < ret.data.tags.length; i++) {
                tags_options.push({ value: ret.data.tags[i]._id, text: ret.data.tags[i].name });
            }
            this.setState({ tags: ret.data.tags, tags_options: tags_options });
            store.set_object('tags', ret.data.tags);
        });
    }
    handleChangeTag(ev, data) {
        let values = [];
        for (let i = 0; i < data.value.length; i++) {
            for (let j = 0; j < this.state.tags.length; j++) {
                if (this.state.tags[j]._id == data.value[i]) {
                    values.push(data.value[i]);
                    break;
                }
            }
        }
        if (!this.state.template.tags) this.state.template.tags = [];
        this.state.template.tags = values;
        this.setState({ template: this.state.template });
    }
    handleAddition(ev, el) {
        // ev.preventDefault();
        http.post_api('/api/tags', { name: el.value }, (ret) => {
            this.state.template.tags.push(ret.data.tag);
            this.state.tags.push(ret.data.tag);
            this.state.tags_options.push({ text: ret.data.tag.name, value: ret.data.tag._id });
            this.setState({ tags: this.state.tags, template: this.state.template, tags_options: this.state.tags_options });
        });
    }
    removeData(key) {
        let data = this.state.project.data;
        delete data[key];
        this.setState({ project: this.state.project });
        http.post_api('/api/cms/project', this.state.project, (ret) => {
            store.set_object('current_project', ret.data.project);
        }, () => { this.setState({ ajax_active: false }) });
    }
    componentWillMount() {
        this.getData(this.state.template_id);
    }
    render() {
        let project_comps = [];
        for (let i = 0; i < this.state.cms_data.length; i++) {
            project_comps.push(<Button.Group size='mini'>
                <Button color='olive' onClick={(e) => { this.addComponent('<' + this.state.cms_data[i].name + ' id="' + this.state.cms_data[i]._id + '" />') }}>{this.state.cms_data[i].name || 'undefined'} [{this.state.cms_data[i]._id}]</Button>

            </Button.Group>)
        }
        let tags = this.state.template.tags || [];
        let tags_name = [];
        let tags_label = [];
        if (this.state.template.tags) {
            for (let i = 0; i < this.state.tags.length; i++) {
                if (tags.indexOf(this.state.tags[i]._id) >= 0) {
                    tags_label.push(this.state.tags[i].name);
                }
            }
        }
        return (
            <Form onSubmit={this.handleSubmit}>
                <Modal closeOnDimmerClick={false} open={this.state.modal} basic onClose={this.toggle} size='fullscreen'>
                    <Modal.Content>
                        <h1>{this.state.template._id ? 'Editar' : 'Novo'} Template</h1>
                        <Grid>
                            <Grid.Column width={12}>
                                <Form.Field>
                                    <label>Nome</label>
                                    <Form.Input fluid={true} type="text" name="template.name" value={this.state.template.name} id="template.name" onChange={this.handleInputChange} />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Form.Field>
                                    <label>Tags</label>
                                    <Dropdown
                                        closeOnChange={true}
                                        multiple
                                        selection
                                        fluid
                                        search
                                        options={this.state.tags_options}
                                        placeholder='Escolha as tags'
                                        allowAdditions
                                        onAddItem={this.handleAddition}
                                        onChange={this.handleChangeTag}
                                        value={tags}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid>
                        <Divider />
                        Componentes neste Projeto: {project_comps}
                        <Divider />
                        <Form.Field>
                            <Button floated='right' color='grey' size='mini' inverted onClick={this.showAddComponent}>Adicionar Componente</Button>
                            <label>HTML</label>
                            <AceEditor
                                ref="aceEditor"
                                mode="html"
                                theme="monokai"
                                highlightActiveLine={true}
                                onChange={this.onChangeBody}
                                name="template_body"
                                editorProps={{ $blockScrolling: true }}
                                value={this.state.template.body}
                                width='100%'
                            />
                        </Form.Field>
                        <ComponentCMS project_id={this.state.project_id} modal={this.state.modalComponents} toggle={this.toggleComponents} add={this.addComponent} />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button disabled={this.state.ajax_active} color="yellow" inverted size="small" onClick={this.toggle}>Cancel</Button>
                        <Button disabled={this.state.ajax_active} type="submit" inverted color="green" size='large' onClick={this.handleSubmit}>Salvar</Button>
                        <Button disabled={this.state.ajax_active} type="submit" inverted color="green" size='large' onClick={this.handleSubmitClose}>Salvar e Fechar</Button>
                    </Modal.Actions>
                </Modal>

            </Form>
        );
    }
}

export default TemplateEdit;
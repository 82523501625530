import React, { Component } from 'react';
import { Button, Divider, Form, Grid, Header, Icon, Input, Label, Modal, Popup, Segment, Table } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import * as http from '../http.js';
import * as store from '../store.js';
import FileEdit from './FileEdit';

class FileManager extends Component {
    constructor(props) {
        super(props);
        let project = props.project || {};
        this.state = {
            ajaxLoading: false,
            files_loaded: false,
            project: project._id,
            project_id: props.project_id,
            files: [],
            uploading: false,
            folders: [],
            term: '',
            delimiter: '/',
            modalFileEdit: false
        };
        this.getData = this.getData.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.toggleFileEdit = this.toggleFileEdit.bind(this);
        this.refreshData = this.refreshData.bind(this);
    }
    componentWillReceiveProps(props) {
        if (!this.state.files_loaded && props.modalFiles) {
            let isAdmin = store.get_object('isAdmin')
            if (isAdmin) {
                this.getData();
            } else {
                this.setState({ old_prefix: 'childhood/' })
                this.getData('childhood/')
            }
        }
    }
    // componentWillMount() {
    //     this.setState({ project:this.state.project });
    // }
    onDrop(files) {
        this.setState({
            files, uploading: true
        });
        http.upload_api('/api/cms/files/' + this.state.project_id + '/upload?path=' + this.state.prefix, files, (ret) => {
            this.getData(this.state.prefix);

            this.setState({ uploading: false });

        }, () => { this.setState({ uploading: false }) });
    }
    refreshData() {
        this.getData(this.state.prefix, this.state.delimiter);
    }
    getData(prefix, delimiter) {
        if (!delimiter) {
            delimiter = '/';
        }
        if (!prefix) {
            prefix = '';
        }
        let prefix_old = this.state.prefix_old;
        if (prefix != this.state.prefix) {
            prefix_old = this.state.prefix;
        }
        this.setState({ ajaxLoading: true });
        http.get_api('/api/cms/files/' + this.state.project_id + '?delimiter=' + delimiter + '&prefix=' + prefix, (resp) => {
            this.setState({ ajaxLoading: false, prefix_old: prefix_old, prefix: prefix, delimiter: delimiter, files_loaded: true, files: resp.data.files, folders: resp.data.folders });
        });
    }
    deleteFile(key) {
        if (key.charAt(0) == '/') key = key.substr(1);
        http.post_api('/api/cms/files/' + this.state.project_id + '/delete', { Key: key }, (ret) => {
            this.getData(this.state.prefix);
        });
    }
    toggleFileEdit(file) {
        this.setState({ currentFile: file, modalFileEdit: !this.state.modalFileEdit });
    }
    render() {
        let files_ui = [];
        for (let i = 0; i < this.state.files.length; i++) {
            console.log("this.state.files[i]", this.state.files[i]);
            let file_extension = (this.state.files[i].url) ? this.state.files[i].url.split('.').pop() : this.state.files[i].path.split('.').pop()
            files_ui.push(<Table.Row>
                <Table.Cell>
                    {this.state.files[i].url}
                </Table.Cell>
                <Table.Cell collapsing>
                    {this.props.addComponent &&
                        <Button type='button' size='mini' color='olive' onClick={(e) => this.props.addComponent(this.state.files[i].url)}>Inserir Arquivo</Button>
                    }
                    {(file_extension != 'pdf' && file_extension != 'jpg' && file_extension != 'png') &&
                        <Button type='button' size='mini' color='olive' onClick={(e) => this.toggleFileEdit(this.state.files[i])}>Editar</Button>
                    }
                    <Popup trigger={<Button icon='trash' size='mini' inverted color='orange'>Excluir</Button>} on='click'>
                        Confirmar exclusão da imagem?
                        <Button type='button' color='orange' size='mini' inverted>Cancelar</Button>
                        <Button type='button' color='green' inverted onClick={(e) => { this.deleteFile(this.state.files[i].url) }}>Confirmar</Button>
                    </Popup>

                </Table.Cell>
            </Table.Row>);
        }
        let folders_ui = [];
        for (let i = 0; i < this.state.folders.length; i++) {
            folders_ui.push(<Table.Row>
                <Table.Cell>
                    <Button type='button' color='blue' inverted size='mini' onClick={(e) => { this.getData(this.state.folders[i].name) }}><Icon name='folder' /> {this.state.folders[i].name}</Button>
                </Table.Cell>
            </Table.Row>);
        }
        return (
            <Modal open={this.props.modalFiles} basic onClose={this.props.toggle} size='small'>
                <Modal.Content scrolling>
                    {/* <Segment basic compact floated='right' padded={false}>
                        <Input size='mini' value={this.state.term} onChange={(e)=>this.setState({term:e.target.value})} inverted={true} name='search' icon='search' iconPosition='right'/>
                        <Button size='mini' icon='search' onClick={this.search}>Buscar</Button>
                    </Segment> */}
                    <h1>Arquivos</h1>
                    <Segment basic loading={this.state.uploading || this.state.ajaxLoading}>
                        <Table celled striped>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        {this.state.prefix != '' &&
                                            <Button type='button' size='mini' inverted color='purple' onClick={(e) => this.getData(this.state.old_prefix)}><Icon name='arrow alternate circle up' /> {this.state.prefix}</Button>
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Segment style={{ padding: 0 }} compact={true} textAlign='right' basic={true} floated='right' padded={false} size='mini'>
                                            <Dropzone onDrop={this.onDrop.bind(this)} style={{ height: 'auto' }}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        Drop
                                                    < Header.Subheader > Click ou arraste aqui para upload</Header.Subheader>
                                                    </div>
                                                )
                                                }
                                            </Dropzone>
                                        </Segment>
                                    </Table.Cell>
                                </Table.Row>
                                {folders_ui}
                                {files_ui}
                            </Table.Body>
                        </Table>
                    </Segment>
                    <FileEdit project_id={this.state.project_id} refreshData={this.refreshData} file={this.state.currentFile} modalEnabled={this.state.modalFileEdit} toggle={this.toggleFileEdit}></FileEdit>
                </Modal.Content>
                <Modal.Actions>
                    <Button type='button' disabled={this.state.ajax_active} color="yellow" inverted size="small" onClick={this.props.toggle}>Fechar</Button>
                </Modal.Actions>
            </Modal >
        )
    }
}

export default FileManager;
import React, { Component } from 'react';
import { Button, Divider, Form, Grid, Header, Icon, Input, Label, Modal, Popup, Segment, Table } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import PubSub from 'pubsub-js';
import * as http from '../../../http.js';
import * as store from '../../../store.js';
import FileEdit from '../../../components/FileEdit';

class FileList extends Component {
    constructor(props) {
        super(props);
        let project = store.get_object('current_project');
        this.state = {
            ajaxLoading: false,
            files_loaded: false,
            project: project,
            // project_id:props.project_id,
            project_id: props.match.params.project_id,
            files: [],
            uploading: false,
            folders: [],
            term: '',
            delimiter: '/',
            modalFileEdit: false
        };
        this.getData = this.getData.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.toggleFileEdit = this.toggleFileEdit.bind(this);
        this.refreshData = this.refreshData.bind(this);
    }
    componentWillReceiveProps(props) {
        if (!this.state.files_loaded && props.modalFiles) {
            this.getData();
        }
    }
    // componentWillMount() {
    //     this.setState({ project:this.state.project });
    // }
    componentDidMount() {
        let isAdmin = store.get_object('isAdmin')
        let prefix = this.props.match.params.prefix

        if (!isAdmin) {
            this.setState({ old_prefix: 'childhood/' })
            prefix = prefix + '/'
        }
        this.getData(prefix, this.state.delimiter);
    }
    onDrop(files) {
        let validFiles = [];
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            if (file.name.indexOf('.jpg') > 0 || file.name.indexOf('.jpeg') > 0 || file.name.indexOf('.gif') > 0 || file.name.indexOf('.svg') > 0 || file.name.indexOf('.png') > 0) {
                if (file.size > 10000000) {
                    PubSub.publish('notification', { type: 'error', message: 'A imagem ' + file.name + ' possui tamanho maior que o permitido (10MB) ' });
                    continue;
                }
            }
            validFiles.push(files[i]);
        }
        if (validFiles.length <= 0) return;
        this.setState({
            files: validFiles, uploading: true
        });
        http.upload_api('/api/cms/files/' + this.state.project_id + '/upload?path=' + this.state.prefix, validFiles, (ret) => {
            this.getData(this.state.prefix);
            this.setState({ uploading: false });

        }, () => { this.setState({ uploading: false }) });
    }
    refreshData() {
        this.getData(this.state.prefix, this.state.delimiter);
    }
    getData(prefix, delimiter) {
        if (!delimiter) {
            delimiter = '/';
        }
        if (!prefix) {
            prefix = '';
        }
        let prefix_old = this.state.prefix_old;
        if (prefix != this.state.prefix) {
            prefix_old = this.state.prefix;
        }
        this.setState({ ajaxLoading: true });
        http.get_api('/api/cms/files/' + this.state.project_id + '?delimiter=' + delimiter + '&prefix=' + prefix, (resp) => {
            this.setState({ ajaxLoading: false, prefix_old: prefix_old, prefix: prefix, delimiter: delimiter, files_loaded: true, files: resp.data.files, folders: resp.data.folders });
        });
    }
    deleteFile(key) {
        if (key.charAt(0) == '/') key = key.substr(1);
        http.post_api('/api/cms/files/' + this.state.project_id + '/delete', { Key: key }, (ret) => {
            this.getData(this.state.prefix);
        });
    }
    toggleFileEdit(file) {
        this.setState({ currentFile: file, modalFileEdit: !this.state.modalFileEdit });
    }
    render() {
        let files_ui = [];

        let domain = '';
        if (this.state.project && this.state.project.simpleConnector) {
            domain = this.state.project.simpleConnector.destination;
        }

        for (let i = 0; i < this.state.files.length; i++) {
            let filename = this.state.files[i].url ? this.state.files[i].url : this.state.files[i].name;
            let file_extension = filename.split('.').pop();
            files_ui.push(<Table.Row>
                <Table.Cell verticalAlign='middle'>
                    {this.state.files[i].url && (this.state.files[i].url.toLowerCase().indexOf('.svg') > 0 || this.state.files[i].url.toLowerCase().indexOf('.jpeg') > 0 || this.state.files[i].url.toLowerCase().indexOf('.jpg') > 0 || this.state.files[i].url.toLowerCase().indexOf('.gif') > 0 || this.state.files[i].url.toLowerCase().indexOf('.png') > 0) &&
                        <img src={domain + this.state.files[i].url} width="80" style={{ verticalAlign: 'middle', marginRight: 6 }} />
                    }
                    {this.state.files[i].url}
                </Table.Cell>
                <Table.Cell collapsing>
                    {this.props.addComponent &&
                        <Button type='button' size='mini' color='olive' onClick={(e) => this.props.addComponent(this.state.files[i].url)}>Inserir Arquivo</Button>
                    }
                    {(file_extension != 'pdf' && file_extension != 'jpg' && file_extension != 'png') &&
                        <Button type='button' size='mini' color='olive' onClick={(e) => this.toggleFileEdit(this.state.files[i])}>Editar</Button>
                    }
                    <Popup trigger={<Button icon='trash' size='mini' inverted color='orange'>Excluir</Button>} on='click'>
                        Confirmar exclusão da imagem?
                        <Button type='button' color='orange' size='mini' inverted>Cancelar</Button>
                        <Button type='button' color='green' inverted onClick={(e) => { this.deleteFile(this.state.files[i].url) }}>Confirmar</Button>
                    </Popup>

                </Table.Cell>
            </Table.Row>);
        }
        let folders_ui = [];
        for (let i = 0; i < this.state.folders.length; i++) {
            folders_ui.push(<Table.Row>
                <Table.Cell>
                    <Button type='button' color='blue' inverted size='mini' onClick={(e) => { this.getData(this.state.folders[i].name) }}><Icon name='folder' /> {this.state.folders[i].name}</Button>
                </Table.Cell>
            </Table.Row>);
        }
        return (
            <div>
                <h1>Arquivos</h1>
                <Segment basic loading={this.state.uploading || this.state.ajaxLoading}>
                    <Segment style={{ padding: 0 }} compact={true} textAlign='right' basic={true} floated='right' padded={false} size='mini'>
                        <Dropzone className='dropzone' onDrop={this.onDrop.bind(this)} style={{ height: 'auto' }}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <b>Drop</b> | <small>Clique ou arraste aqui para upload</small> |
                                    <Popup
                                        trigger={<Icon name='help circle' size='large' />}
                                        content={<div>
                                            <p>- Destaque (box) notícias: <b>378x222px</b></p>
                                            <p>- Interna notícias (corpo do texto): <b>996px</b> máximo de largura (sistema adapta a imagem)</p>
                                            <p>- Carrossel destaque home: <b>1200x576px</b></p>
                                        </div>}
                                        inverted
                                    />
                                </div>
                            )
                            }
                        </Dropzone>
                    </Segment>
                    <Table celled striped>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    {this.state.prefix != '' &&
                                        <Button type='button' size='mini' inverted color='purple' onClick={(e) => this.getData(this.state.old_prefix)}><Icon name='arrow alternate circle up' /> {this.state.prefix}</Button>
                                    }
                                </Table.Cell>
                            </Table.Row>
                            {folders_ui}
                            {files_ui}
                        </Table.Body>
                    </Table>
                </Segment>
                <FileEdit project_id={this.state.project_id} refreshData={this.refreshData} file={this.state.currentFile} modalEnabled={this.state.modalFileEdit} toggle={this.toggleFileEdit}></FileEdit>
            </div>
        )
    }
}

export default FileList;
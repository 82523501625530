import React, { Component } from 'react';
import { Button, Divider, Form, Grid, Icon, Input, Label, Menu as MenuS, Modal, Popup, Segment, Tab as TabS } from 'semantic-ui-react'
import EditableContent from './EditableContent';
import * as store from '../../store.js';

class Tab extends Component {
    static defaultProps = {
        updateComponent: () => {
            // console.log('Event Handler');
        },
        componentGetData: () => {
            // console.log('Event Handler');
        },
        getProject: () => {
            // console.log('Event Handler');
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            modal: props.display,
            items: [],
            panes: [],
            activeIndex:0,
            profile: store.get_object('profile'),
            content_tags:[]
        };
        this.handleChange = this.handleChange.bind(this);
        this.addTab = this.addTab.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.selectTab = this.selectTab.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.updateTab = this.updateTab.bind(this);
    }
    componentDidMount() {
        let val = this.props.componentGetData({ id: this.props.id });
        let tags = store.get_object('content_tags');
        if(val && val._id)val = val.content_data;
        if(!val)val=[];
        this.setState({ items: val, content_tags:tags});
    }
    handleChange(value, el, key) {
        // this.setState({ text: value });
        el[key] = value;
        this.setState({ items: this.state.items });
        this.props.updateComponent({ id: this.props.id, name: 'Tabs', value: this.state.items });
    }
    changeTab(idx,name){
        let items = this.state.items;
        items[idx].name = name;
        this.setState({items});
    }
    selectTab(ev,data){
        this.setState({activeIndex:data.activeIndex});
    }
    addTab() {
        // this.state.items.push({name:'Nova Tab',value:''});
        if(typeof this.state.items == 'undefined' || !this.state.items)this.state.items=[];
        this.state.items.splice(this.state.activeIndex+1, 0,{name:'Nova Tab',value:''});
        this.setState({ items: this.state.items });
        this.props.updateComponent({ id: this.props.id, name: 'Tabs', value: this.state.items });
    }
    removeItem() {
        this.state.items.splice(this.state.activeIndex, 1);
        let idx = this.state.activeIndex - 1;
        if(idx < 0)idx=0;
        this.setState({ items: this.state.items, activeIndex:idx});
        this.props.updateComponent({ id: this.props.id, name: 'Tabs', value: this.state.items });
    }
    updateTab(e){
        this.state.items[e.id].value=e.value;
        this.setState({items:this.state.items});
        this.props.updateComponent({ id: this.props.id, name: 'Tabs', value: this.state.items });
    }
    render() {
        if (this.state.error) {
            return <h1>Caught an error.</h1>
        }
        let panes = [];
        for(let i=0; i < this.state.items.length;i++){
            panes.push({
                menuItem: this.state.items[i].name, pane:{key:'tab'+i,content:<TabS.Pane >
                    <Form.Field>
                        <label>Nome</label>
                        <Form.Input fluid={true} type="text" value={this.state.items[i].name} onChange={(e)=>{this.changeTab(i,e.target.value)}} />
                    </Form.Field>
                    <EditableContent id={i} text={this.state.items[i].value} componentGetData={(e)=>{return this.state.items[e.id].value}} updateComponent={(e)=>this.updateTab(e)} />
                </TabS.Pane>
                }
            });
        }
        return (
            <Segment>
                <h4>{this.props.name || 'Tabs'}</h4>
                <Button type='button' floated='right' compact size='mini' icon='plus' onClick={this.addTab}></Button>
                <Button type='button' floated='right' compact size='mini' icon='minus' onClick={this.removeItem}></Button>
                <TabS onTabChange={this.selectTab} activeIndex={this.state.activeIndex} menu={{ pointing: true }} panes={panes}  renderActiveOnly={false} />
            </Segment>
        )
    }
}

export default Tab;
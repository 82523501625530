import React, { Component } from 'react';
import {
    Route,
    Switch
} from 'react-router-dom'
import PubSub from 'pubsub-js';
import * as store from '../../../store.js';
import * as http from '../../../http.js';

import ProjectHome from './project_home';
import TemplatesList from './templates/templates_list';
import ProjectEdit from './project_edit.js';
import ComponentsList from './components/components_list';
import ContentEdit from './content/content_edit.js';
import ContentDetails from './content/content_details.js';

class ProjectBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: store.get_object('profile'),
            isOpen: false,
            project: {},
            modalFiles: false
        };
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.getData = this.getData.bind(this);
    }
    toggleSidebar() {
        this.setState({
            sidebarOpen: !this.state.sidebarOpen
        });
    }
    componentWillMount() {
        // this.getData(this.state.project_id);
        if (this.props.match.params.id == null || this.props.match.params.id == 'undefined') {
            this.props.history.push('/cms');
            return;
        }

        this.getData(this.props.match.params.id);
    }
    getData(id) {
        if(id=='edit')return;
        http.get_api('/api/cms/project/' + id, (resp) => {
            store.set_object(resp.data.project._id, resp.data.project);
            store.set_object('current_project', resp.data.project);
            this.setState({ project: resp.data.project });
            PubSub.publish('project_loaded', resp.data.project);
        });
    }
    render() {
        return (
            <div className="">
                <Switch>
                    <Route path={"/cms/projects/edit/:id"} component={ProjectEdit} />
                    <Route path={"/cms/projects/:id/templates"} component={TemplatesList} />
                    <Route path={"/cms/projects/:id/components"} component={ComponentsList} />
                    <Route path={"/cms/projects/:id/content/:content_id/edit/:tag?"} component={ContentEdit} />
                    <Route exact path={"/cms/projects/:id/content/:content_id"} component={ContentDetails} />
                    <Route exact path={"/cms/projects/:id/tag/:tag"} component={ProjectHome} />
                    <Route path={"/cms/projects/:id"} component={ProjectHome} />
                </Switch>
            </div>
        );
    }
}
export default ProjectBase;
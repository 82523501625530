import React, { Component } from 'react';
import { Button, Form, Grid, Popup, Segment } from 'semantic-ui-react'
import PubSub from 'pubsub-js';
// import Select from 'react-select';
// import { AsyncCreatable } from 'react-select';
// import Autocomplete from 'react-autocomplete';
import * as http from '../http.js';
import * as store from '../store.js';
import SelectSingle from '../components/select_single.js';

class Regsiter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: null,
            user: {
                email: '',
                password: '',
                companies: []
            },
            create: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createCompany = this.createCompany.bind(this);
        this.changeCompany = this.changeCompany.bind(this);
        // this.searchCompanies = this.searchCompanies.bind(this);
        this.promptTextCreator = this.promptTextCreator.bind(this);
    }
    componentDidMount() {
        PubSub.publish('notification', { test: 'Test' });
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let names = target.name.split('.');
        var prop = this.state;
        var el = this.state[names[0]];
        for (var i in names) {
            if (typeof prop[names[i]] != 'object') break;
            prop = prop[names[i]];
        }
        prop[names[names.length - 1]] = value;
        this.setState({
            [names[0]]: el
        });
    }

    changeCompany(el) {
        let company = el;
        this.setState({ company: company });
    }

    createCompany(name) {
        // let user = this.state.user;
        let company = { name: name }
        this.setState({ company: company, create: true });
        return true;
    }

    promptTextCreator(el) {
        return 'Deseja criar a Empresa ' + el;
    }

    handleSubmit(event) {
        event.preventDefault();
        http.post('/api/public/profile', { user: this.state.user, company: this.state.company }, (ret) => {
            store.set_object('profile', ret.data.profile);
            store.set('token', ret.data.token);
            this.props.history.push('/login');
        });
    }
    render() {
        return (
            <Segment className="fullHeight">
                <Grid verticalAlign='middle' columns={4} centered className="fullHeight">
                    <Grid.Column stretched>
                        <h1>Cadastre-se</h1>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <label>Nome:</label>
                                    <Form.Input type="text" name="user.first_name" id="user.first_name" onChange={this.handleInputChange} />
                                </Form.Field>
                                <Form.Field>
                                    <label>Sobrenome:</label>
                                    <Form.Input type="text" name="user.last_name" id="user.last_name" onChange={this.handleInputChange} />
                                </Form.Field>
                            </Form.Group>
                            <Form.Field>
                                <label>Email:</label>
                                <Form.Input type="email" name="user.email" id="user.email" onChange={this.handleInputChange} />
                            </Form.Field>
                            <Form.Field>
                                <label>Senha:</label>
                                <Form.Input type="password" name="user.password" id="user.password" onChange={this.handleInputChange} />
                            </Form.Field>
                            <Form.Field>
                                <label>Confirme sua Senha:</label>
                                <Form.Input type="password" name="user.passwordConfirmation" id="user.passwordConfirmation" onChange={this.handleInputChange} />
                            </Form.Field>
                            <Form.Field>
                                <label>Empresa</label>
                                {this.state.create &&
                                    <div>
                                        <Form.Field>
                                            <label>Nome:</label>
                                            <Form.Input type="text" name="company.name" id="company.name" value={this.state.company.name} onChange={this.handleInputChange} />
                                            <Button color='orange' inverted size='mini' onClick={(e) => this.setState({ create: false, company: null })}>Cancelar</Button>
                                        </Form.Field>
                                    </div>
                                }
                                {!this.state.create &&
                                    <div>
                                        <SelectSingle label='name' placeholder='Buscar empresa' endpoint='/api/company/search/' value={this.state.company} handleElementChange={(e) => { this.changeCompany(e) }} />
                                    </div>
                                }
                            </Form.Field>
                            {!this.state.company &&
                                <Form.Field>
                                    <Button color='olive' size='mini' onClick={(e) => this.setState({ create: true, company: {} })}>Crie usa Empresa</Button>
                                </Form.Field>
                            }
                            <Button type='submit' size='large' color='green'>Cadastre-se</Button>
                        </Form>
                    </Grid.Column>
                </Grid >
            </Segment >
        );
    }
}

export default Regsiter;

import React, { Component } from "react";
import { Router } from "react-router";
// import { Button, ButtonGroup, Col, Container, Form, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import {
  Button,
  Card,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Label,
  Modal,
  Popup,
  Segment,
  Divider,
} from "semantic-ui-react";
import { Link, Route } from "react-router-dom";
import JsxParser from "react-jsx-parser";
import PubSub from "pubsub-js";
import { Async } from "react-select";
import queryString from "query-string";
import moment from "moment";
import locale_pt from "moment/locale/pt-br";

import Parser from "html-react-parser";

import * as http from "../../../../http.js";
import * as store from "../../../../store.js";
import template_edit from "../templates/template_edit";
import EditableContent from "../../../../components/content/EditableContent";
import Menu from "../../../../components/content/Menu";
import TemplateDetail from "../../../../components/TemplateDetail";
import FileManager from "../../../../components/FileManager";
import { ReactDOM } from "react-dom";

moment.locale("pt-br", locale_pt);

class ContentEdit extends Component {
  constructor(props) {
    super(props);
    let project = store.get_object("current_project");
    this.state = {
      modal: true,
      content: {},
      project: project,
      text: "",
      project_id: props.match.params.id,
      content_id: props.match.params.content_id,
      ajax_active: false,
      tag: props.match.params.tag,
      tags: [],
      tags_options: [],
      template_options: [],
      searchQuery: "",
      modal_preview: false,
      modalFiles: false,
      cmsData: {},
      tags_label: [],
      profile: store.get_object("profile"),
      rendering: true,
    };
    this.toggle = this.toggle.bind(this);
    this.toggleFileManager = this.toggleFileManager.bind(this);
    this.showPreview = this.showPreview.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTag = this.handleChangeTag.bind(this);
    this.handleChangeTemplate = this.handleChangeTemplate.bind(this);
    this.getTags = this.getTags.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    // this.getTemplate = this.getTemplate.bind(this);
    this.normalize = this.normalize.bind(this);
    this.removeTemplate = this.removeTemplate.bind(this);
    this.updateComponent = this.updateComponent.bind(this);
    this.updateProjectData = this.updateProjectData.bind(this);
    this.getProject = this.getProject.bind(this);
    this.addHighlight = this.addHighlight.bind(this);
    this.addPDF = this.addPDF.bind(this);
    this.saveContent = this.saveContent.bind(this);
    this.saveContentClose = this.saveContentClose.bind(this);
    this.publishContent = this.publishContent.bind(this);
    this.addFile = this.addFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.removeHighlight = this.removeHighlight.bind(this);
    this.getTemplatesByName = this.getTemplatesByName.bind(this);
    moment.locale("pt-BR");
  }
  updateComponent(ev, data) {
    let el = this.state.content;
    if (!el.data) el.data = {};
    el.data[data.id] = data.value;
    this.setState({ content: el });
  }
  toggle() {
    if (this.state.modal) {
      let path = "/cms/projects/details/" + this.props.match.params.id;
      this.props.history.push(path);
    }
    this.setState({ modal: !this.state.modal });
  }
  toggleFileManager(action) {
    this.setState({ modalFiles: !this.state.modalFiles, addAction: action });
  }
  addFile(url) {
    this.state.addAction(url);
  }
  addHighlight(url) {
    this.state.content.highlight = url;
    this.setState({ content: this.state.content, modalFiles: false });
  }
  addPDF(url) {
    // this.state.content.highlight = url;
    if (!this.state.content.files) this.state.content.files = [];
    this.state.content.files.push({ url: url });
    this.setState({ content: this.state.content, modalFiles: false });
  }
  showPreview() {
    this.setState({ ajax_active: true });
    let content = JSON.parse(JSON.stringify(this.state.content));
    content.project = this.state.project_id;
    if (!content.slug) {
      content.slug = this.normalize(this.state.content.title);
    }
    if (content.template && content.template._id)
      content.template = content.template._id;
    http.post_api(
      "/api/cms/content/preview",
      { content: content, data: this.state.cmsData },
      (ret) => {
        let domain = "";
        let project = this.state.project;

        // if (window.location.href.lastIndexOf('localhost') >= 0) {
        //     domain = 'http://' + this.state.project_id + '.s3-website-sa-east-1.amazonaws.com'
        // } else if (project.simpleConnector && project.simpleConnector.domain) {
        //     domain = 'http://' + project.simpleConnector.domain;
        // }

        domain = "http://" + project.simpleConnector.domain;
        // let preview_window = window.open(domain + '/' + this.state.project_id + '_' + content.slug + '_preview', "Preview", "toolbar=yes,location=yes,menubar=yes");
        let preview_window = window.open(
          domain +
            "/" +
            this.state.project_id +
            "_" +
            content.slug +
            "_preview" +
            "?dt=" +
            new Date().getTime(),
          "Preview",
          "toolbar=yes,location=yes,menubar=yes"
        );
        let el = this;
        var timer = setInterval(function () {
          if (preview_window && preview_window.closed) {
            clearInterval(timer);
            http.post_api(
              "/api/cms/files/" + el.state.project_id + "/delete",
              { Key: el.state.project_id + "_" + content.slug + "_preview" },
              (ret) => {}
            );
          }
        }, 500);
        this.setState({ ajax_active: false });
      },
      () => {
        this.setState({ ajax_active: false });
      }
    );
  }

  close() {
    this.setState({ modal: false });
  }

  handleChange(value) {
    this.setState({ text: value });
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let names = target.name.split(".");
    var prop = this.state;
    var el = this.state[names[0]];
    for (var i in names) {
      if (typeof prop[names[i]] != "object") break;
      prop = prop[names[i]];
    }
    prop[names[names.length - 1]] = value;
    this.setState({
      [names[0]]: el,
    });
  }
  handleElementChange(val, el, key) {
    el = this.state[el];
    el[key] = val;
    this.setState({ el });
  }
  handleSearchChange = (e, { searchQuery }) => this.setState({ searchQuery });
  handleAddition(ev, el) {
    // ev.preventDefault();
    http.post_api("/api/tags", { name: el.value }, (ret) => {
      // this.state.content.tags.push(ret.data.tag._id);
      this.state.tags.push(ret.data.tag);
      this.state.tags_options.push({
        text: ret.data.tag.name,
        value: ret.data.tag._id,
      });
      this.setState({
        tags: this.state.tags,
        content: this.state.content,
        tags_options: this.state.tags_options,
      });
    });
  }

  handleChangeTag(ev, data) {
    let values = [];
    for (let i = 0; i < data.value.length; i++) {
      for (let j = 0; j < this.state.tags.length; j++) {
        if (this.state.tags[j]._id == data.value[i]) {
          values.push(data.value[i]);
          break;
        }
      }
    }
    if (!this.state.content.tags) this.state.content.tags = [];
    this.state.content.tags = values;
    // this.state.content.tags = data.value;
    this.setState({ content: this.state.content });
  }

  handleChangeTemplate(ev, data) {
    this.state.content.template = this.state.templates_map[data.value];
    if (!this.state.content.data) this.state.content.data = {};
    this.setState({ content: this.state.content });
  }
  removeTemplate(ev, data) {
    this.state.content.template = null;
    this.setState({ content: this.state.content });
  }
  getData(id) {
    if (id == "new") {
      this.setState({
        content: { template: null },
        project_id: this.state.project_id,
      });
      this.getTags();
    } else {
      http.get_api("/api/cms/content/" + id, (ret) => {
        // this.setState({ content: ret.data.content, project: project});
        store.set_object("content", ret.data.content);
        this.getTags();
        this.setState({
          content: ret.data.content,
          cmsData: ret.data.cms_data,
          project_id: this.state.project_id,
        });
      });
    }
    // let isAdmin = store.get_object('isAdmin')
    // if (isAdmin) {
    this.getTemplates();
    // } else {
    // this.getTemplatesByName('Notícia')
    // }
  }
  getTags() {
    http.get_api("/api/tags", (ret) => {
      let tags_options = [];
      let tags_label = [];
      let tags_map = {};
      if (!this.state.content.tags) this.state.content.tags = [];
      for (let i = 0; i < ret.data.tags.length; i++) {
        tags_map[ret.data.tags[i].name] = ret.data.tags[i]._id;
      }
      if (this.state.content_id == "new") {
        this.state.content.tags = [tags_map[this.state.tag]];
      }
      for (let i = 0; i < ret.data.tags.length; i++) {
        tags_options.push({
          value: ret.data.tags[i]._id,
          text: ret.data.tags[i].name,
        });
        if (this.state.content.tags.indexOf(ret.data.tags[i]._id) >= 0) {
          tags_label.push(ret.data.tags[i].name);
        }
      }
      store.set_object("content_tags", tags_label);
      // store.set_object('tags_options', tags_options);
      store.set_object("tags", ret.data.tags);
      this.setState({
        rendering: false,
        content: this.state.content,
        tags: ret.data.tags,
        tags_options: tags_options,
        tags_label: tags_label,
      });
    });
  }
  getTemplates() {
    http.get_api("/api/cms/template/" + this.state.project_id, (ret) => {
      let template_options = [];
      let templates_map = [];
      for (let i = 0; i < ret.data.templates.length; i++) {
        templates_map[ret.data.templates[i]._id] = ret.data.templates[i];
        template_options.push({
          value: ret.data.templates[i]._id,
          text: ret.data.templates[i].name,
        });
        for (let j = 0; j < ret.data.templates[i].tags.length; j++) {
          if (this.state.tag == ret.data.templates[i].tags[j].name) {
            this.state.content.template = ret.data.templates[i];
            if (!this.state.content.data) this.state.content.data = {};
            this.setState({ content: this.state.content });
          }
        }
      }
      this.setState({
        templates: ret.data.templates,
        templates_map: templates_map,
        template_options: template_options,
      });
    });
  }
  getTemplatesByName(name) {
    http.get_api(
      "/api/cms/template/" + this.state.project_id + "/name/" + name,
      (ret) => {
        let template_options = [];
        let templates_map = [];
        for (let i = 0; i < ret.data.templates.length; i++) {
          templates_map[ret.data.templates[i]._id] = ret.data.templates[i];
          template_options.push({
            value: ret.data.templates[i]._id,
            text: ret.data.templates[i].name,
          });
        }
        this.setState({
          templates: ret.data.templates,
          templates_map: templates_map,
          template_options: template_options,
        });
      }
    );
  }
  getProject() {
    let project = store.get_object("current_project");
    return project;
  }
  updateProjectData(data) {
    this.setState((state, props) => {
      state.cmsData = data;
      return null;
    });
    // store.set_object('current_project', this.state.project);
  }
  componentWillMount() {
    this.getData(this.state.content_id);
  }
  normalize(el) {
    if (!el) return;
    el = el.toLowerCase();
    el = el.replace(new RegExp("[\"'”“]", "g"), "");
    el = el.replace(new RegExp("[àáâãäå]", "g"), "a");
    el = el.replace(new RegExp("æ", "g"), "ae");
    el = el.replace(new RegExp("ç", "g"), "c");
    el = el.replace(new RegExp("[èéêë]", "g"), "e");
    el = el.replace(new RegExp("[ìíîï]", "g"), "i");
    el = el.replace(new RegExp("ñ", "g"), "n");
    el = el.replace(new RegExp("[òóôõö]", "g"), "o");
    el = el.replace(new RegExp("œ", "g"), "oe");
    el = el.replace(new RegExp("[ùúûü]", "g"), "u");
    el = el.replace(new RegExp("[ýÿ]", "g"), "y");
    el = el.replace(new RegExp("&#215;", "g"), "x");
    el = el.replace(new RegExp("!?@*(){}[]\\/", "g"), "");
    el = el.replace(new RegExp("[?]", "g"), "");
    el = el.replace(new RegExp("[;,:/$#%]", "g"), "");
    el = el.replace(/ /g, "-");
    return el;
  }
  saveContent(event, target, cb) {
    event.preventDefault();
    this.state.content.project = this.state.project_id;
    if (!this.state.content.slug) {
      this.state.content.slug = this.normalize(this.state.content.title);
    }
    this.setState({ ajax_active: true });

    // prevent from inserting a null value in tag array
    let content = this.state.content;
    if (content.tags[0] == undefined) {
      content.tags = [];
    }

    console.log("page content: ", content);

    http.post_api(
      "/api/cms/content",
      { content: content, data: this.state.cmsData },
      (ret) => {
        this.state.content._id = ret.data.content._id;
        this.setState({ ajax_active: false, content: this.state.content });
        // PubSub.publish('content_updated');
        if (cb) cb();
      },
      () => {
        this.setState({ ajax_active: false });
        if (cb) cb();
      }
    );
  }
  saveContentClose(event, target) {
    event.preventDefault();
    this.saveContent(event, target, () => {
      // this.props.history.push('/cms/projects/' + this.state.content.project);
      window.history.back();
    });
  }
  changeStatus(status) {
    this.setState({ headerLoading: true });
    this.state.content.status = status;
    this.state.content.project = this.state.project_id;
    if (!this.state.content.slug) {
      this.state.content.slug = this.normalize(this.state.content.title);
    }
    http.post_api(
      "/api/cms/content/publish",
      { content: this.state.content, data: this.state.cmsData },
      (ret) => {
        this.state.content._id = ret.data.content._id;
        this.setState({ publishPopup: false, headerLoading: false });
      },
      () => {
        this.setState({ headerLoading: false });
      }
    );
  }
  publishContent(event) {
    event.preventDefault();
    this.state.content.project = this.state.project_id;
    if (!this.state.content.slug) {
      this.state.content.slug = this.normalize(this.state.content.title);
    }
    this.setState({ ajax_active: true });
    http.post_api(
      "/api/cms/content/publish",
      { content: this.state.content, data: this.state.cmsData },
      (ret) => {
        this.setState({ ajax_active: false });
        this.props.history.push("/cms/projects/" + this.state.content.project);
      },
      () => {
        this.setState({ ajax_active: false });
      }
    );
  }
  removeHighlight(idx) {
    this.state.content.highlight = null;
    this.setState({ content: this.state.content });
  }
  removeFile(idx) {
    this.state.content.files = [];
    this.setState({ content: this.state.content });
    // http.delete_api('/api/cms/content/' + this.state.content._id + '/file/' + idx, (ret) => {
    //     // this.setState({ ajax_active: false });
    //     // this.props.history.push('/cms/projects/' + this.state.content.project);
    // }, () => { this.setState({ ajax_active: false }) });
  }
  onDrop(files) {
    this.setState({
      files,
      uploading: true,
    });
    http.upload_api(
      "/api/cms/content/" + this.state.content._id + "/upload",
      files,
      (ret) => {
        this.getData(this.state.prefix);
        this.setState({ uploading: false });
      },
      () => {
        this.setState({ uploading: false });
      }
    );
  }
  uploadFile(files) {
    http.upload_api(
      "/api/cms/content/" + this.state.content._id + "/file/",
      files,
      (ret) => {
        // this.setState({ ajax_active: false });
        // this.props.history.push('/cms/projects/' + this.state.content.project);
      },
      () => {
        this.setState({ ajax_active: false });
      }
    );
  }
  render() {
    let tags = this.state.content.tags || [];
    let tags_name = [];

    let files_ui = [];
    if (this.state.content.files) {
      for (let i = 0; i < this.state.content.files.length; i++) {
        files_ui.push(
          <Label>
            
            {this.state.content.files[i].url}
            <Popup
              position="bottom right"
              size="mini"
              trigger={<i class="delete icon"> </i>}
              on="click"
            >
              Confirmar a exclusão do arquivo ?
              <Button
                type="button"
                onClick={(e) => {
                  this.removeFile(i);
                }}
                size="mini"
                inverted
                color="green"
              >
                
                Confirmar
              </Button>
            </Popup>
          </Label>
        );
      }
    }
    let highlight_img = null;
    if (this.state.project && this.state.project.simpleConnector) {
      highlight_img = "http://" + this.state.project.simpleConnector.domain;
      if (highlight_img.slice(-1) != "/") highlight_img += "/";
      if (
        this.state.content &&
        this.state.content.highlight &&
        this.state.content.highlight.charAt(0) == "/"
      ) {
        highlight_img += this.state.content.highlight.substring(1);
      } else {
        highlight_img += this.state.content.highlight;
      }
    }

    let isAdmin = this.state.profile.role == "admin";
    let isIndex = this.state.tags_label.indexOf("index") >= 0;

    return (
      <Segment
        basic
        loading={this.state.ajax_active}
        loading={this.state.rendering}
      >
        
        {!this.state.rendering && (
          <Form onSubmit={this.handleSubmit}>
            <Header as="h1">
              <Segment
                basic={true}
                floated="right"
                loading={this.state.headerLoading}
              >
                Status: &nbsp;
                <Button.Group>
                  <Button
                    toggle
                    onClick={(e) => {
                      this.changeStatus("trash");
                    }}
                    active={this.state.content.status == "trash"}
                  >
                    
                    Lixeira
                  </Button>
                  <Button
                    toggle
                    onClick={(e) => {
                      this.changeStatus("draft");
                    }}
                    active={
                      this.state.content.status != "trash" &&
                      this.state.content.status != "publish"
                    }
                  >
                    
                    Rascunho
                  </Button>
                  <Popup
                    position="bottom right"
                    size="mini"
                    open={this.state.publishPopup}
                    onOpen={(e) => {
                      this.setState({ publishPopup: true });
                    }}
                    onClose={(e) => {
                      this.setState({ publishPopup: false });
                    }}
                    trigger={
                      <Button
                        toggle
                        active={this.state.content.status == "publish"}
                      >
                        
                        Publicado
                      </Button>
                    }
                    on="click"
                  >
                    Confirmar a Publicação da Página ?
                    <Button
                      type="button"
                      onClick={(e) => {
                        this.changeStatus("publish");
                      }}
                      size="mini"
                      inverted
                      color="green"
                    >
                      
                      Confirmar
                    </Button>
                  </Popup>
                </Button.Group>
                &nbsp; &nbsp; Url :
                <Label> /{this.normalize(this.state.content.title)}</Label>
              </Segment>
              {this.state.content._id ? "Editar" : "Nova"}
              Página
            </Header>
            {!isAdmin && isIndex && (
              <div>
                <h2>
                  
                  {this.state.content.title} <br />
                  <small> {this.state.content.subtitle} </small>
                </h2>
              </div>
            )}
            {(isAdmin || !isIndex) && (
              <div>
                <Form.Field>
                  <label> Título(até 40 caracteres) </label>
                  <Form.Input
                    fluid={true}
                    type="text"
                    name="content.title"
                    value={this.state.content.title}
                    id="content.title"
                    onChange={(e) => {
                      this.handleElementChange(
                        e.target.value,
                        "content",
                        "title"
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label> Slug </label>
                  <Form.Input
                    fluid={true}
                    type="text"
                    name="content.slug"
                    value={this.state.content.slug}
                    id="content.slug"
                    onChange={(e) => {
                      this.handleElementChange(
                        e.target.value,
                        "content",
                        "slug"
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label> Subtítulo(até 120 caracteres) </label>
                  <Form.Input
                    fluid={true}
                    type="text"
                    name="content.subtitle"
                    value={this.state.content.subtitle}
                    id="content.subtitle"
                    onChange={(e) => {
                      this.handleElementChange(
                        e.target.value,
                        "content",
                        "subtitle"
                      );
                    }}
                  />
                </Form.Field>
              </div>
            )}
            {isAdmin && (
              <div>
                <Form.Field>
                  <label> Label </label>
                  <Form.Input
                    fluid={true}
                    type="text"
                    name="content.label"
                    value={this.state.content.label}
                    id="content.label"
                    onChange={(e) => {
                      this.handleElementChange(
                        e.target.value,
                        "content",
                        "label"
                      );
                    }}
                  />
                </Form.Field>
              </div>
            )}
            {(this.state.content.highlight ||
              this.state.tags_label.indexOf("noticias") >= 0) && (
              <Grid>
                
                {(this.state.content.highlight ||
                  this.state.tags_label.indexOf("noticias") >= 0) && (
                  <Grid.Column width={11}>
                    <Grid>
                      <Grid.Column width={10}>
                        <Form.Field>
                          <label> Destaque </label>
                          <Form.Group inline width={16}>
                            <Form.Input
                              width={15}
                              fluid={true}
                              type="text"
                              name="content.highlight"
                              value={this.state.content.highlight}
                              id="content.highlight"
                              onChange={(e) => {
                                this.handleElementChange(
                                  e.target.value,
                                  "content",
                                  "highlight"
                                );
                              }}
                            />
                            <Form.Button
                              type="button"
                              icon="image"
                              onClick={(e) => {
                                this.toggleFileManager(this.addHighlight);
                              }}
                            />
                            <Form.Button
                              type="button"
                              icon="trash"
                              onClick={(e) => {
                                this.removeHighlight();
                              }}
                            />
                          </Form.Group>
                        </Form.Field>
                      </Grid.Column>
                      {this.state.content.highlight &&
                        this.state.project &&
                        this.state.project.simpleConnector && (
                          <Grid.Column
                            width={4}
                            textAlign="left"
                            verticalAlign="middle"
                          >
                            <Image
                              verticalAlign="middle"
                              src={highlight_img}
                              size="tiny"
                            />
                          </Grid.Column>
                        )}
                    </Grid>
                  </Grid.Column>
                )}
                {this.state.tags_label.indexOf("noticias") >= 0 && (
                  <Grid.Column width={5}>
                    <Form.Field>
                      <label> Editoria </label>
                      <Form.Input
                        fluid={true}
                        type="text"
                        name="content.category"
                        value={this.state.content.category}
                        id="content.category"
                        onChange={(e) => {
                          this.handleElementChange(
                            e.target.value,
                            "content",
                            "category"
                          );
                        }}
                      />
                    </Form.Field>
                  </Grid.Column>
                )}
              </Grid>
            )}
            {(!this.state.content.template ||
              (this.state.content.template &&
                !this.state.content.template._id)) && (
              <Form.Field>
                <label> Template </label>
                <Dropdown
                  // closeOnChange={true}
                  selection
                  fluid
                  search
                  options={this.state.template_options}
                  placeholder="Escolha um template"
                  onChange={this.handleChangeTemplate}
                  value={this.state.content.template}
                />
              </Form.Field>
            )}
            {this.state.content.template && (
              <div className="field" style={{ marginTop: 10 }}>
                <TemplateDetail
                  project_id={this.state.project_id}
                  project={this.state.project}
                  cmsData={this.state.cmsData}
                  updateProjectData={this.updateProjectData}
                  removeTemplate={this.removeTemplate}
                  content={this.state.content}
                  tags={this.state.tags}
                  template={this.state.content.template}
                />
              </div>
            )}
            {(isAdmin || !isIndex) && (
              <Segment>
                <Form.Field>
                  <label> Tags </label>
                  <Dropdown
                    closeOnChange={true}
                    multiple
                    selection
                    fluid
                    search
                    options={this.state.tags_options}
                    placeholder="Escolha as tags"
                    allowAdditions
                    onAddItem={this.handleAddition}
                    onChange={this.handleChangeTag}
                    value={tags}
                  />
                </Form.Field>
              </Segment>
            )}
            {(isAdmin || !isIndex) && (
              <div>
                
                {this.state.content.files && (
                  <Form.Field>
                    <label> Arquivos </label> {files_ui}
                  </Form.Field>
                )}
                {(!this.state.content.files ||
                  this.state.content.files.length == 0) && (
                  <Button
                    type="button"
                    icon="file"
                    onClick={(e) => {
                      this.toggleFileManager(this.addPDF);
                    }}
                  >
                    Adicionar Arquivo
                  </Button>
                )}
                <Divider />
              </div>
            )}
            <Segment>
              <label>
                
                Meta <small> (usado pelo Google) </small>
              </label>
              <Form.Field>
                <label> Description </label>
                <Form.Input
                  fluid={true}
                  type="text"
                  name="content.meta"
                  value={this.state.content.meta}
                  id="content.meta"
                  onChange={(e) => {
                    this.handleElementChange(e.target.value, "content", "meta");
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  
                  Keywords
                  <small> (palavras - chave separadas por vírgula) </small>
                </label>
                <Form.Input
                  fluid={true}
                  type="text"
                  name="content.keywords"
                  value={this.state.content.keywords}
                  id="content.keywords"
                  onChange={(e) => {
                    this.handleElementChange(
                      e.target.value,
                      "content",
                      "keywords"
                    );
                  }}
                />
              </Form.Field>
            </Segment>
            <Segment basic floated="right">
              <Button
                onClick={(e) => {
                  window.history.back();
                }}
                disabled={this.state.ajax_active}
                color="orange"
                inverted
                size="small"
              >
                
                Cancel
              </Button>
              <Button
                type="submit"
                inverted
                color="green"
                size="large"
                onClick={this.saveContent}
              >
                
                Salvar
              </Button>
              <Button
                type="submit"
                inverted
                color="green"
                size="large"
                onClick={this.saveContentClose}
              >
                
                Salvar e Fechar
              </Button>
            </Segment>
            <Segment basic floated="left">
              <Button
                type="button"
                inverted
                color="blue"
                size="mini"
                onClick={this.showPreview}
              >
                
                Preview
              </Button>
            </Segment>
            <FileManager
              project_id={this.state.project_id}
              modalFiles={this.state.modalFiles}
              toggle={this.toggleFileManager}
              addComponent={this.addFile}
            />
          </Form>
        )}
      </Segment>
    );
  }
}

export default ContentEdit;

import React, { Component } from 'react';
import { Button, Divider, Form, Grid, Header, Icon, Input, Label, Modal, Popup, Segment, Table } from 'semantic-ui-react';
import AceEditor from 'react-ace';
import 'brace/theme/monokai';
import 'brace/mode/html';
import * as http from '../http.js';
import * as store from '../store.js';

class FileEdit extends Component {
    constructor(props) {
        super(props);
        let project = props.project || {};
        this.state = {
            ajaxLoading: false,
            modalFileEdit: false,
            file: {},
            fileEdit:{}
        }
        this.toggle = this.toggle.bind(this);
        this.onChangeBody = this.onChangeBody.bind(this);
        this.save = this.save.bind(this);
        this.saveAndClose = this.saveAndClose.bind(this);
    }
    toggle() {
        this.state.modalFileEdit = !this.state.modalFileEdit;
        this.setState({ modalFileEdit: this.state.modalFileEdit });
    }
    componentWillReceiveProps(props) {
        if (props.file) {
            this.setState({ ajaxLoading: true });
            http.get_api('/api/cms/files/' + props.project_id + '/get?key=' + props.file.key, (resp) => {
                this.setState({ fileEdit: resp.data, ajaxLoading: false })
                // this.setState({ prefix_old: prefix_old, prefix: prefix, delimiter: delimiter, files_loaded: true, files: resp.data.files, folders: resp.data.folders });
            });
        }
    }
    save(cb) {
        this.state.fileEdit.Key = this.props.file.key;
        this.setState({ ajaxLoading: true });
        http.post_api('/api/cms/files/' + this.props.project_id,this.state.fileEdit, (resp) => {
            // this.setState({ file: resp.data });
            this.setState({ ajaxLoading: false });
            if (cb) cb();
            // this.setState({ prefix_old: prefix_old, prefix: prefix, delimiter: delimiter, files_loaded: true, files: resp.data.files, folders: resp.data.folders });
        });
    }
    saveAndClose() {
        this.save(() => {
            this.props.toggle();
            this.props.refreshData();
        })
    }
    onChangeBody(data) {
        this.state.fileEdit.Body = data;
        this.setState({fileEdit:this.state.fileEdit});
    }
    render() {
        return (
            <Modal open={this.props.modalEnabled} toggle={this.props.toggle} basic onClose={this.props.toggle} size='fullscreen'>
                <Modal.Content scrolling>
                    <Segment basic loading={this.state.ajaxLoading}>
                        <h1>Editar Arquivo</h1>
                        <AceEditor
                            ref="aceEditor"
                            mode="html"
                            theme="monokai"
                            highlightActiveLine={true}
                            onChange={this.onChangeBody}
                            name="template_body"
                            editorProps={{ $blockScrolling: true }}
                            value={this.state.fileEdit.Body}
                            width='100%'
                        />
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="orange" inverted onClick={this.props.toggle}>Fechar</Button>
                    <Button positive icon='checkmark' inverted labelPosition='right' content='Salvar' onClick={this.save} />
                    <Button positive icon='close' inverted labelPosition='right' content='Salvar e Fechar' onClick={this.saveAndClose} />
                </Modal.Actions>
            </Modal>
        )
    }
}

export default FileEdit;
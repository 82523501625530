import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter
} from 'react-router-dom'

import * as serviceWorker from './serviceWorker';
import Base from './pages/base';
import * as http from './http.js';
import * as store from './store.js';

http.get('/api/account/CMS',(ret)=> {
    let account_id = ret.data.companyToken;
    let prev_account_id = store.get('account_id');
    store.set('account_id',account_id);
    if(account_id !== prev_account_id){
      window.location.reload();
    }
  });

ReactDOM.render(<BrowserRouter><Base/></BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

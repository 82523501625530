import 'semantic-ui-css/semantic.min.css';
import React, { Component } from 'react';
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import PubSub from 'pubsub-js';
import BasePublic from './base_public';
import CMSBase from './cms/base';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import * as store from '../store.js';

const authStatus = {
  isAuthenticated: false
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    authStatus.isAuthenticated ? (
      <Component {...props} />
    ) : (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
      )
  )} />
)

class Base extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
    PubSub.subscribe('notification', this.displayNotification);
  }
  componentWillMount() {
    let token = store.get('token');
    let profile = store.get('profile');
    if (profile && token) {
      authStatus.isAuthenticated = true;
    }
  }  
  displayNotification = (ev, msg) => {
    if (!msg || !msg.message) return;
    switch (msg.type) {
      case 'error':
        NotificationManager.error(msg.message.toString());
        break;
      case 'info':
        NotificationManager.info(msg.message.toString());
        break;
      case 'warning':
        NotificationManager.warning(msg.message.toString());
        break;
      case 'success':
        NotificationManager.success(msg.message.toString());
        break;
      default:
        NotificationManager.info(msg.message.toString());
        break;
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div className='fullHeight'>
        <Switch>
          <PrivateRoute path={"/cms/projects/:id"} component={CMSBase} />
          <PrivateRoute path={"/cms"} component={CMSBase} />
          <Route path={"/login"} component={BasePublic} />
          <Route path={"/cadastrese"} component={BasePublic} />
          <Route path={"/"} exact component={BasePublic} />
        </Switch>
        <NotificationContainer />
      </div>
    );
  }
}

export default Base;
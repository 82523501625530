import React, { Component } from 'react';
import { Button, Card, Checkbox, Divider, Form, Grid, Icon, Image, Input, Label, Modal, Popup, Segment } from 'semantic-ui-react'
import FileManager from '../FileManager';
import * as store from '../../store.js';

class MenuItem extends Component {
    constructor(props) {
        super(props);
    }
}

class Carousel extends Component {
    static defaultProps = {
        updateComponent: () => {
            // console.log('Event Handler');
        },
        componentGetData: () => {
            // console.log('Event Handler');
        },
        getProject: () => {
            // console.log('Event Handler');
        },
        getProfile: () => {
            // console.log('Event Handler');
        }
    }
    constructor(props) {
        super(props);
        let project = store.get_object('current_project');
        this.state = {
            modal: props.display,
            items: [],
            modalFiles: false,
            active_item:null,
            project:project
        };
        this.handleChange = this.handleChange.bind(this);
        this.addCarrouselItem = this.addCarrouselItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.addImage = this.addImage.bind(this);
        this.toggleFileManager = this.toggleFileManager.bind(this);
        this.changeTextColor = this.changeTextColor.bind(this);
    }
    componentDidMount() {
        let val = this.props.componentGetData({ id: this.props.id });
        if(val && val._id)val = val.content_data;
        if (!val) val = [];
        this.setState({ items: val })
    }
    handleChange(value, el, key) {
        // this.setState({ text: value });
        el[key] = value;
        this.setState({ items: this.state.items });
        this.props.updateComponent({ id: this.props.id, name:'Carousel', value: this.state.items });
    }
    addCarrouselItem() {
        if(!this.state.items)this.state.items=[];
        this.state.items.push({ name: 'Novo' });
        this.setState({ items: this.state.items });
        this.props.updateComponent({ id: this.props.id, name:'Carousel',value: this.state.items });
    }
    removeItem(idx) {
        this.state.items.splice(idx, 1);
        this.setState({ items: this.state.items });
        this.props.updateComponent({ id: this.props.id, name:'Carousel', value: this.state.items });
    }
    addImage(url) {
        this.state.items[this.state.active_item].image=url;
        this.setState({items:this.state.items,modalFiles:false});
    }
    toggleFileManager(idx) {
        this.setState({active_item:idx,modalFiles: !this.state.modalFiles });
    }
    changeTextColor(e,data,idx){
        this.state.items[idx].text_inverse=data.checked;
        this.setState({items:this.state.items});
        this.props.updateComponent({ id: this.props.id, name:'Carousel', value: this.state.items });
        // item.text_inverse=data.value;this.setState({items:this.state.items}
    }
    render() {
        if (this.state.error) {
            return <h1>Caught an error.</h1>
        }
        
        let cards = [];
        for (let i = 0; i < this.state.items.length; i++) {
            let item = this.state.items[i];
            item.text_inverse=false;
            cards.push(<Card>
                <Card.Content>
                    {item.image && this.state.project && this.state.project.simpleConnector &&
                        <Image src={'http://'+this.state.project.simpleConnector.domain+item.image}/>
                    }
                    <Card.Description>
                        <Form.Group widths='equal' inline>
                            <Form.Field inline>
                                <Button floated='right' style={{marginTop:20}} icon="image" onClick={(e)=>{this.toggleFileManager(i)}}/>                        
                                <Form.Input label='Imagem' fluid value={item.image} onChange={(e) => { this.handleChange(e.target.value, item, 'image') }} />
                            </Form.Field>
                        </Form.Group>
                        <div>
                            <Form.Input label='Título' fluid value={item.title} onChange={(e) => { this.handleChange(e.target.value, item, 'title') }} />
                            <Form.Input label='Descrição' fluid value={item.description} onChange={(e) => { this.handleChange(e.target.value, item, 'description') }} />
                            <Form.Input label='Link' fluid value={item.link} onChange={(e) => { this.handleChange(e.target.value, item, 'link') }} />
                        </div>
                        <Form.Field>
                            <label>Texto {item.text_inverse?"Branco":"Preto"}</label>
                            <Checkbox toggle value={true} checked={item.text_inverse} onChange={(e,data)=>{this.changeTextColor(e,data,i)}}/>
                        </Form.Field>
                    </Card.Description>
                    <Card.Content extra>
                        <Popup size='mini' trigger={<Button type='button' floated='right' compact size='mini' color='orange' inverted icon='trash'></Button>} on='click'>
                            Confirmar a exclusão? <Button type='button' onClick={(e)=>{this.removeItem(i)}} size='mini' inverted color='orange'>Confirmar</Button>
                        </Popup>
                    </Card.Content>
                </Card.Content>
            </Card>)
        }
        return (
            <Segment>
                <h4>{this.props.name || 'Carrosel Imagens'}</h4>
                <Button type='button' floated='right' compact size='mini' icon='plus' onClick={this.addCarrouselItem}></Button>
                {cards.length > 0 &&
                <Card.Group>
                    {cards}
                </Card.Group>
                }
                {cards.length==0 &&
                    <span>Adicione um item à Galeria</span>
                }
                <FileManager project_id={this.state.project._id} modalFiles={this.state.modalFiles}  toggle={this.toggleFileManager} addComponent={this.addImage}/>
            </Segment>
        )
    }
}

export default Carousel;
import axios from 'axios/dist/axios.js';
import * as store from './store.js';
import PubSub from 'pubsub-js';

//let API_URL = 'https://core.fluent.com.br'; //producao
let API_URL = 'http://15.228.58.33:8080'; //nova producao

// let API_URL = 'http://core-homologacao.fluent.com.br'; //homologacao

if(window.location.href.lastIndexOf('localhost') >= 0){
  API_URL = 'http://localhost:8080';
}

if(window.location.href.lastIndexOf('dev.tasks.') >= 0){
  API_URL = 'http://localhost:9191';
}

// API_URL = 'https://core.fluent.com.br';
// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers['Accept']="application/json";
    window.ajax_active=true;
    return config;
  }, function (error) {
    // Do something with request error
    if(error){
      console.log('Error');
      console.log(error);
    }
    window.ajax_active=false;
    return Promise.reject(error);
  });


axios.interceptors.response.use(function (response) {
  // if(response.headers.user_tasks){
  //   store.set('user_tasks',response.headers.user_tasks);
  // }
  window.ajax_active=false;
  for(var i in response.data){
    if(i==='notifications'){
      for(var j=0; j < response.data[i].length; j++){
        PubSub.publish('notification', response.data[i][j]);
      }
    }
  }
  return response;
}, function (error) {
  window.ajax_active=false;
  let response = error.response;
  if(response && response.data){
    for(var i in response.data){
      if(i==='notifications'){
        for(var j=0; j < response.data[i].length;j++){
          PubSub.publish('notification', response.data[i][j]);
        }
      }
    }
  }
  return Promise.reject(response);
});

// exports.$message = null;

export function get(url,cb,cb_error){
  let fullUrl = API_URL + url
  return axios.get(fullUrl, {responseType: 'json', headers: {'Authorization':'Bearer '  + store.get('account_id')}}).then(cb).catch(cb_error);
}

export function post(url, data, cb, cb_error){
    return axios.post(API_URL + url, data, {responseType: 'json', headers: {'Authorization':'Bearer ' + store.get('account_id')}}).then(cb).catch((err)=>{
      if(cb_error)cb_error(err)
    })
}

export function get_api(url,cb,cb_error) {
  let fullUrl = API_URL + url
  // let fullUrl = API_URL + url
  return axios.get(fullUrl,{responseType: 'json', headers: {'Authorization':'Bearer ' + store.get('token') + ' ' + store.get('account_id')}}).then(cb).catch(cb_error);
}

export function delete_api(url,cb,cb_error){
  axios.delete(API_URL + url,{responseType: 'json', headers: {'Authorization':'Bearer ' + store.get('token') + ' ' + store.get('account_id')}}).then(cb).catch(cb_error);
}

export function post_api(url,data,cb,cb_error){
  axios.post(API_URL + url,data,{responseType: 'json', headers: {'Authorization':'Bearer ' + store.get('token') + ' ' + store.get('account_id')}}).then(cb).catch(cb_error);
}

export function patch_api(url,data,cb,cb_error){
  axios.patch(API_URL + url,data,{responseType: 'json', headers: {'Authorization':'Bearer ' + store.get('token') + ' ' + store.get('account_id')}}).then(cb).catch(cb_error);
}

export function upload_api(url,files,cb,cb_error){
  console.log(files);
  let formData = new FormData();
  // formData.append('files',files);
  for(let i=0; i < files.length;i++){
    console.log('File');
    console.log(typeof files[i]);
    console.log(JSON.stringify(files[i]));
    formData.append('files',files[i]);
  }
  axios.put(API_URL + url,formData,{responseType: 'json', timeout: 600000, headers: {'Content-Type': 'multipart/form-data','Authorization':'Bearer ' + store.get('token') + ' ' + store.get('account_id')}}).then(cb).catch(cb_error);
}

export function upload_single_api(url,file,cb,cb_error){
  let formData = new FormData();
  // formData.append('files',files);
  formData.append('file',file);
  axios.put(API_URL + url,formData,{responseType: 'json', timeout: 600000, headers: {'Content-Type': 'multipart/form-data','Authorization':'Bearer ' + store.get('token') + ' ' + store.get('account_id')}}).then(cb).catch(cb_error);
}

export function set_auth(token){
    // axios.post('/api/auth',data,{responseType: 'json'}).then(cb).catch(cb_error);
}

// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
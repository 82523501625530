import React, { Component } from 'react';
import {
    Link,
    Redirect,
    Route,
    Switch
} from 'react-router-dom'
import PubSub from 'pubsub-js';
import { Header, Icon, Menu, Segment, Sidebar } from 'semantic-ui-react'
import * as store from '../../store.js';

import ProjectsList from './projects/projects_list';
import ProjectBase from './projects/project_base';
import CompanyBase from './company/company_base';
import FileList from './projects/file_list';

class Base extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project_id: props.match.params.id,
            project: {},
            profile: store.get_object('profile'),
            isOpen: false
        };
        PubSub.subscribe('project_loaded', (ev, project) => {
            this.setState({ project: project, project_id:project._id });
        });
        PubSub.subscribe('project_unloaded', (ev) => {
            this.setState({ project: null, project_id:null });
            store.remove('current_project');
        });
        this.toggleSidebar = this.toggleSidebar.bind(this);
    }
    componentMount() {
        let project = store.get_object('current_project');
        if (project) {
            this.setState({ project: project });
        }
    }
    componentDidMount() {
        // console.log("is admin: ", store.get_object('isAdmin'))
    }
    toggleSidebar() {
        this.setState({
            sidebarOpen: !this.state.sidebarOpen
        });
    }
    logout(ev) {
        store.remove('token');
        store.remove('profile');
        store.remove('current_project');
        store.remove('admin')
        window.location.href = "/";
    }
    render() {
        let isAdmin = store.get_object('isAdmin')
        let file_list_link = ''
        if (isAdmin) {
            file_list_link = '/cms/fileManager/' + this.state.project_id
        } else {
            file_list_link = '/cms/fileManager/' + this.state.project_id + '/prefix/childhood'
        }
        
        return (
            <div className='fullHeight'>
                <Sidebar.Pushable as={Segment}>
                    
                        <Sidebar as={Menu} animation='push' width='thin' visible={this.state.sidebarOpen} icon='labeled' vertical inverted>
                            <Menu.Item name='home'>
                                <small>Bem-vindo</small> {this.state.profile.data.user.first_name}
                                <br />{this.state.profile.company.name}
                            </Menu.Item>
                            <Menu.Item name='help'>
                                <a href="https://www.childhood.org.br/cms_guia_uso.pdf" target="_blank">
                                    <Icon name='help'/> Guia de Uso
                                </a>
                            </Menu.Item>
                            
                            {store.get_object('isAdmin') &&
                                <div>
                                    <Menu.Item name='projects' as={Link} to={'/cms/projects'} onClick={this.toggleSidebar}>
                                        Projetos
                                    </Menu.Item>
                                    <Menu.Item name='manage' as={Link} to={'/cms/company'} onClick={this.toggleSidebar}>
                                        <small>Gerenciar</small><br />
                                        Empresa
                                    </Menu.Item>
                                    <Menu.Item name='manage' as={Link} to={'/cms/projects/' + (this.state.project_id)} onClick={this.toggleSidebar}>
                                        Todas as Páginas
                                    </Menu.Item>
                                </div>
                            }
                            <Menu.Item name='sair' onClick={this.logout}>
                                Sair
                            </Menu.Item>
                        </Sidebar>
                    <Sidebar.Pusher>
                        <Menu borderless={true} inverted attached='top'>
                            <Menu.Item
                                onClick={this.toggleSidebar}
                            >
                                <Icon name='bars' />
                            </Menu.Item>
                            <Menu.Item
                                as={Link}
                                to={'/cms/projects/' + this.state.project_id + '/tag/home'}
                            >
                                    <Icon name='object ungroup' />
                                    Principal
                            </Menu.Item>
                            <Menu.Item
                                as={Link}
                                to={'/cms/projects/' + (this.state.project_id) + '/tag/publicacoes'}
                            >
                                <Icon name='pen square' />
                                Publicações
                            </Menu.Item>
                            {this.state.project_id && this.state.project_id != 'new' &&
                                <Menu.Item
                                    as={Link}
                                    to={'/cms/projects/' + (this.state.project_id) + '/tag/noticias'}
                                >
                                    <Icon name='pen square' />
                                    Notícias
                                </Menu.Item>
                            }
                            {this.state.profile.role=='admin' && this.state.project_id && this.state.project_id != 'new' &&
                                <Menu.Item
                                    as={Link}
                                    to={'/cms/projects/' + (this.state.project_id) + '/tag/notag'}
                                >
                                    <Icon name='pen square' />
                                    Conteúdo Fixo
                                </Menu.Item>
                            }
                            {this.state.profile.role=='admin' && this.state.project_id && this.state.project_id != 'new' &&
                                <Menu.Item
                                    as={Link}
                                    to={'/cms/projects/' + (this.state.project_id) + '/templates'}
                                >
                                    <Icon name='object group' />
                                    Templates
                            </Menu.Item>
                            }
                            {this.state.profile.role=='admin' && this.state.project_id && this.state.project_id != 'new' &&
                                <Menu.Item
                                    as={Link}
                                    to={'/cms/projects/' + this.state.project_id + '/components'}
                                >
                                    <Icon name='object ungroup' />
                                    Componentes
                            </Menu.Item>
                            }
                            {/* <Menu.Item
                                as={Link}
                                to={this.toggleFileManager}
                                onClick={this.toggleFileManager}
                            > */}
                            
                            <Menu.Item
                                as={Link}
                                to={file_list_link}
                            >
                                    <Icon name='object ungroup' />
                                    Arquivos
                            </Menu.Item>
                            {this.state.project &&
                                <Menu.Menu position='right'>
                                    <Menu.Item align='right'>
                                        <Header inverted>
                                            <Header.Subheader>projeto:</Header.Subheader>
                                            {this.state.project.name}
                                        </Header>
                                    </Menu.Item>
                                </Menu.Menu>
                            }
                        </Menu>
                        <div className='fullHeight'>
                            <Switch>
                                <Route path={"/cms/fileManager/:project_id/prefix/:prefix"} component={FileList} />
                                <Route path={"/cms/fileManager/:project_id"} component={FileList} />
                                <Route path={"/cms/projects/:id"} component={ProjectBase} />
                                <Route path={"/cms/company"} component={CompanyBase} />
                                <Route path={"/cms/projects"} component={ProjectsList} />
                                <Route path={"/cms"} component={ProjectsList} />
                            </Switch>
                        </div>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
                {/* <FileManager project_id={this.state.project_id} modalFiles={this.state.modalFiles} toggle={this.toggleFileManager} addComponent={this.addComponent} /> */}
            </div>
        );
    }
}

export default Base;
import React, { Component } from 'react';
import { Button, Card, Form, Grid, Icon, Input, Message, Modal, Segment } from 'semantic-ui-react'
import {
  Link,
  Route
} from 'react-router-dom'
import PubSub from 'pubsub-js';
import * as http from '../../../../http.js';
import ComponentEdit from './component_edit.js';

const ComponentCMS = (props) => {
  const component = props.component;
  let ajax_active = false;
  let removeComponent = (component) => {
    if (window.confirm('Deseja realmente excluir o componente?')) {
      ajax_active = true;
      http.delete_api('/api/cms/component/' + component._id, (resp) => {
        PubSub.publish('component_updated');
        ajax_active = false;
      }, () => { this.ajax_active = false; });
    }
  }
  if (!component) return null;
  return (
    <Card>
      <Card.Content>
        <Card.Header>
          <Link to={'/cms/projects/'+props.project_id+'/components/details/' + component._id}>
            {component.name}
          </Link>
        </Card.Header>
      </Card.Content>
      <Card.Content extra>
        <Button as={Link} to={'/cms/projects/'+props.project_id+'/components/edit/' + component._id} size="mini" inverted color='blue'>
          <Icon name='edit' /> Editar
        </Button>
        <Button size="mini" inverted color='orange' disabled={ajax_active} onClick={(e) => { e.stopPropagation(); removeComponent(component) }} title="Remover">
          <Icon name='trash' />
          Excluir
        </Button>
      </Card.Content>
    </Card >
  );
}

const ComponentItems = (props) => {
  // if(!props.components)props.components={};
  if (!props.components) {
    return (
      <Card.Group></Card.Group>
    );
  }
  const components = props.components.map((component) =>
    <ComponentCMS component={component} key={component._id} project_id={props.project_id}></ComponentCMS>
  );
  return (
    <Card.Group>{components}</Card.Group>
  );
}

class ComponentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      project_id: props.match.params.id
    };
    this.getData = this.getData.bind(this);
    PubSub.subscribe('component_updated', () => {
      this.getData();
    });
  }
  componentWillMount() {
    this.getData();
  }
  getData() {
    http.get_api('/api/cms/component/'+this.state.project_id+'/list', (resp) => {
      this.setState({ components: resp.data });
    });
  }

  render() {
    return (
      <Segment basic={true}>
        <h1>
          Componentes &nbsp;
            <Link to={'/cms/projects/'+this.state.project_id+'/components/edit/new'}>
            <Button color="olive" inverted size="small">Novo Componente</Button>
          </Link>
        </h1>
        {(!this.state.components || this.state.components.length == 0) &&
          <Message color="grey">Nenhum componente cadastrado, adicione um agora</Message>
        }
        <ComponentItems components={this.state.components} project_id={this.state.project_id}></ComponentItems>
        <Route path={'/cms/projects/:id/components/edit/:component_id'} component={ComponentEdit} />
      </Segment>
    );
  }
}

export default ComponentsList;
import React, { Component } from 'react';
import { Button, Card, Form, Grid, Icon, Input, Message, Modal, Segment } from 'semantic-ui-react'
import {
  Link,
  Route
} from 'react-router-dom'
import PubSub from 'pubsub-js';
import * as http from '../../../../http.js';
import * as store from '../../../../store.js';
import TemplateEdit from './template_edit.js';

const Template = (props) => {
  const template = props.template;
  const project_id = props.project_id;
  let ajax_active = false;
  let removeTemplate = (template) => {
    if (window.confirm('Deseja realmente excluir o template?')) {
      ajax_active = true;
      http.delete_api('/api/cms/template/' + project_id + '/' + template._id, (resp) => {
        PubSub.publish('template_updated');
        ajax_active = false;
      }, () => { this.ajax_active = false; });
    }
  }
  if (!template) return null;
  return (
    <Card>
      <Card.Content>
        <Card.Header>
          <Link to={'/cms/projects/'+project_id+'/templates/details/' + template._id}>
            {template.name}
          </Link>
        </Card.Header>
      </Card.Content>
      <Card.Content extra>
        <Button as={Link} to={'/cms/projects/'+project_id+'/templates/edit/' + template._id} size="mini" inverted color='blue'>
          <Icon name='edit' /> Editar
        </Button>
        <Button size="mini" inverted color='orange' disabled={ajax_active} onClick={(e) => { e.stopPropagation(); removeTemplate(template) }} title="Remover">
          <Icon name='trash' />
          Excluir
        </Button>
      </Card.Content>
    </Card >
  );
}

const TemplateItems = (props) => {
  const templates = props.templates.map((template) =>
    <Template template={template} key={template._id} project_id={props.project_id}></Template>
  );
  return (
    <Card.Group>{templates}</Card.Group>
  );
}

class TemplatesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
        templates: [],
        project:{},
        project_id: props.match.params.id,
    };
    this.getData = this.getData.bind(this);
    PubSub.subscribe('template_updated',this.getData);
  }
  componentWillMount() {
    this.setState({project:store.get_object('current_project')});
    this.getData();
  }
  getData() {
    http.get_api('/api/cms/template/'+this.state.project_id+'/list', (resp) => {
      this.setState({ templates: resp.data });
    });
  }

  render() {
    return (
      <Segment basic={true}>
        <h1>
          Templates &nbsp;
            <Link to={'/cms/projects/'+this.state.project_id+'/templates/edit/new'}>
                <Button color="olive" inverted size="small">Novo Template</Button>
            </Link>
        </h1>
        {(!this.state.templates || this.state.templates.length == 0) &&
          <Message color="grey">Nenhum template cadastrado, adicione um agora</Message>
        }
        <TemplateItems templates={this.state.templates} project_id={this.state.project_id}></TemplateItems>
        <Route path={'/cms/projects/:id/templates/edit/:template_id'} component={TemplateEdit} />
      </Segment>
    );
  }
}

export default TemplatesList;
import React, { Component } from 'react';
import {
    Link
} from 'react-router-dom'
import PubSub from 'pubsub-js';
import Dropzone from 'react-dropzone';
import { Button, Form, Header, Icon, Popup, Segment } from 'semantic-ui-react'
import * as http from '../../../http.js';
import ProjectContent from './project_content.js';

import * as store from '../../../store.js';

class ProjectHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: {},
            project_id: props.match.params.id,
            profile: store.get_object('profile'),
            content: React.createRef(),
            tag:props.match.params.tag
        };
        this.publishAll = this.publishAll.bind(this);
        this.renamePDFs = this.renamePDFs.bind(this);
        PubSub.subscribe('project_loaded', (ev, project) => {
            this.setState({ project: project, project_id: project._id });
        });
    }
    componentWillReceiveProps(props){
        this.setState({tag:props.match.params.tag});
    }
    onDropXml(files) {
        this.setState({
            uploading: true
        });
        http.upload_api('/api/cms/files/' + this.state.project_id + '/import/xml', files, (ret) => {
            // this.getData(this.state.prefix);
            // this.setState({uploading:false});
        }, () => { this.setState({ uploading: false }) });
    }
    publishAll() {
        http.get_api('/api/cms/content/' + this.state.project_id + '/publish/all', (ret) => {

        });
    }
    renamePDFs() {
        http.get_api('/api/cms/files/' + this.state.project_id + '/renamePdfs', (ret) => {
        });
    }
    componentWillMount() {
        let project = store.get_object('current_project');
        if (project) {
            this.state.project = project;
        }
    }
    render() {
        if (!this.state.project) return null;
        return (
            <Segment basic={true}>
                <Header as="h1">
                    {this.state.project.name}
                    &nbsp;|&nbsp;
                    {this.state.tag !='home' &&
                        <Button floated='right' size='large' color='green' as={Link} to={'/cms/projects/' + this.state.project_id + '/content/new/edit/'+this.state.tag} >Adicionar {this.state.tag?(this.state.tag.charAt(0).toUpperCase() + this.state.tag.slice(1)):''}</Button>
                    }

                    {this.state.tag != 'home' &&
                        <Segment style={{ margin: 0, padding: 2 }} floated='right' basic compact={true}>
                            <Form size='small' onSubmit={(e) => { this.state.content.current.search(this.state.searchInput, this.state.tag,null,true) }}>
                                <Form.Field inline>
                                    <label>Busca</label>
                                    <input name='searchInput' value={this.state.searchInput} onChange={(e) => { this.setState({ searchInput: e.target.value }) }}></input>
                                    <Button size="small" color='blue' icon='search'></Button>
                                    &nbsp;&nbsp;|&nbsp;
                            </Form.Field>
                            </Form>
                        </Segment>
                    }
                    {this.state.profile.role == 'admin' &&
                        <Button as={Link} color='blue' to={'/cms/projects/edit/' + this.state.project_id} size="mini" inverted>
                            <Icon name='edit' />
                            Editar
                        </Button>
                    }
                    {this.state.profile.role == 'admin' &&
                        <div style={{ display: 'inline-block' }}>

                            <Popup size='mini' trigger={<Button size='mini' color='olive' inverted>RePublicar Páginas</Button>} on='click'>
                                Desejar republicar todas as páginas com status publicado? <Button type='button' onClick={(e) => { this.publishAll() }} size='mini' inverted color='orange'>Confirmar</Button>
                            </Popup>
                            <Popup size='mini' trigger={<Button size='mini' color='olive' inverted>Renomear PDFs</Button>} on='click'>
                                Desejar renomear os PDFs? <Button type='button' onClick={(e) => { this.renamePDFs() }} size='mini' inverted color='orange'>Confirmar</Button>
                            </Popup>
                        </div>
                    }
                    <Header.Subheader>
                    </Header.Subheader>
                </Header>
                <ProjectContent ref={this.state.content} project_id={this.state.project_id} tag={this.state.tag} />
            </Segment >
        );
    }
}

export default ProjectHome;
import React, { Component } from 'react';
import {
    Link,
    Route,
    Switch
} from 'react-router-dom'
import PubSub from 'pubsub-js';
import moment from 'moment';
import { Button, Card, Grid, Header, Icon, Label, List, Message, Pagination, Popup, Segment } from 'semantic-ui-react'
import * as http from '../../../http.js';
import * as store from '../../../store.js';

const Content = (props) => {
    const content = props.content;
    const project_id = props.project_id;
    let project = store.get_object('current_project');
    let ajax_active = false;
    let removeContent = (content) => {
        ajax_active = true;
        http.delete_api('/api/cms/content/' + content._id, (resp) => {
            ajax_active = false;
            // this.props.getData();
            PubSub.publish('content_updated');
        }, () => { ajax_active = false; });
    }
    if (!content) return null;
    let tags = []
    if (props.content.tags) {
        for (let i = 0; i < props.content.tags.length; i++) {
            tags.push(<Label size='mini' inverted color='blue'>{props.content.tags[i].name}</Label>)
        }
    }
    let showPreview = () => {
        http.post_api('/api/cms/content/preview', { content: props.content }, (ret) => {
            let domain = '';
            if (project.simpleConnector && project.simpleConnector.domain) {
                domain = 'http://' + project.simpleConnector.domain;
            }
            let preview_window = window.open(domain + '/' + project_id + '_' + props.content.slug + '_preview', "Preview", "toolbar=yes,location=yes,menubar=yes");
            let el = this;
            var timer = setInterval(function () {
                if (preview_window.closed) {
                    clearInterval(timer);
                    http.post_api('/api/cms/files/' + project._id + '/delete', { Key: project._id + '_' + props.content.slug + '_preview' }, (ret) => { });
                }
            }, 500);
        });
    }
    return (
        <List.Item>
            <List.Content floated='right'>
                <Button onClick={showPreview} size="mini" inverted color='blue' icon='eye' alt='Preview'></Button>
                <Button as={Link} to={'/cms/projects/' + project_id + '/content/' + content._id + '/edit'} size="mini" inverted color='blue'>
                    <Icon name='edit' /> Editar
                </Button>
                <Popup size='mini' trigger={<Button size="mini" inverted color='orange' disabled={ajax_active} title="Remover"><Icon name='trash' />Excluir</Button>} on='click'>
                    Confirmar a exclusão? <Button type='button' onClick={(e) => { e.stopPropagation(); removeContent(content) }} size='mini' inverted color='orange'>Confirmar</Button>
                </Popup>
            </List.Content>
            <List.Content floated='right'>
                <Link floated='right' to={'/cms/projects/' + project_id + '/content/' + content._id + '/edit'}>
                    {tags} <Label size='mini'>{moment(content.created).format('DD/MM/YY HH:mm')}</Label>
                </Link>
            </List.Content>
            <List.Content>
                <Link to={'/cms/projects/' + project_id + '/content/' + content._id + '/edit'}>
                    {content.title}
                </Link>
            </List.Content>
        </List.Item>
    );
}

class ProjectDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project_id: props.project_id,
            content_list: [],
            latest: [],
            source: null,
            tag: props.tag,
            term: null,
            loadmore_search: false,
            loadmore: false,
            activePage: (store.get('page_' + props.project_id) || 1)
        };
        this.getData = this.getData.bind(this);
        this.getDataByTag = this.getDataByTag.bind(this);
        this.getLatestNews = this.getLatestNews.bind(this);
        this.search = this.search.bind(this);
        this.getDataByTag(props.tag);
        if (props.tag == 'home') {
            this.getLatestNews('noticias')
        }
        PubSub.subscribe('content_updated', () => {
            this.getData();
            if (this.state.tags) {
                this.getDataByTag(this.state.tag);
            } else {
                this.getData(this.state.activePage);
            }
        });
    }
    componentWillReceiveProps(newProps) {
        if (newProps.tag) {
            if (newProps.tag && newProps.tag != this.state.tag) {
                store.remove('page_' + this.state.project_id)
                this.getDataByTag(newProps.tag);
                if (newProps.tag == 'home') {
                    this.getLatestNews('noticias')
                }
                this.setState({ tag: newProps.tag })
            }
        } else {
            this.getData(this.state.activePage);
        }
    }
    getData(page, created) {
        console.log('Get Project Content');
        let url = '/api/cms/project/' + this.state.project_id + '/content';
        let loadmore = false;
        if (created) {
            url = url + '?end=' + created;
        } else if (page) {
            store.set('page_' + this.state.project_id, page);
            url = url + '?page=' + page
        } else if (store.get('page_' + this.state.project_id)) {
            url = url + '?page=' + store.get('page_' + this.state.project_id);
        }
        http.get_api(url, (ret) => {
            let content = ret.data.content_list;
            if (ret.data.content_list.length >= 9) {
                loadmore = true;
            }
            if (created) {
                if (!this.state.content_list) this.state.content_list = [];
                content = this.state.content_list.concat(content);
            }
            this.setState({ content_list: content, count: ret.data.count, source: 'content', loadmore: loadmore });
        });
    }
    getDataByTag(tag, created) {
        console.log('Get Project Content Tag');
        if (!tag) {
            tag = this.state.tag
        }
        let loadmore = false;
        if (tag == 'home') {
            let url = '/api/cms/project/' + this.state.project_id + '/contentByTag/home';
            http.get_api(url, (ret) => {
                this.setState({ content_list: ret.data.content_list, count: ret.data.count });
            });
        } else {
            let url = '/api/cms/project/' + this.state.project_id + '/contentByTag/' + tag;
            if (created) {
                url = url + '?end=' + created;
            }
            http.get_api(url, (ret) => {
                let content = ret.data.content_list;
                if (ret.data.content_list.length >= 9) {
                    loadmore = true;
                }
                if (created) {
                    if (!this.state.content_list) this.state.content_list = [];
                    content = this.state.content_list.concat(content);
                }
                this.setState({ content_list: content, count: ret.data.count, source: 'tag', loadmore: loadmore });
            });
        }
    }
    getLatestNews(tag) {
        let url = '/api/cms/project/' + this.state.project_id + '/contentByTag/' + tag + '?page=1';
        http.get_api(url, (ret) => {
            this.setState({ latest: ret.data.content_list });
        });
    }
    search(term, tag, created, clear) {
        let url = '/public/api/cms/search/' + this.state.project_id + '?term=' + term + '&tag=' + tag;
        if (created) {
            url += '&end=' + created;
        }
        let loadmore_search = false;
        http.get_api(url, (ret) => {
            if (!clear) {
                if (!this.state.content_list) this.state.content_list = [];
                this.state.content_list = this.state.content_list.concat(ret.data);
            } else {
                this.state.content_list = ret.data;
            }
            if (ret.data.length >= 9) {
                loadmore_search = true
            }
            this.setState({ term: term, content_list: this.state.content_list, source: 'search', loadmore_search: loadmore_search });
        });
    }

    render() {
        let pages = Math.ceil(this.state.count / 10);
        let content_ui = [];
        let content_home = [];

        if (this.state.tag == 'home') {
            for (let i = 0; i < this.state.content_list.length; i++) {
                content_home.push(<Button size='large' basic color="blue" as={Link} to={'/cms/projects/' + this.state.project_id + '/content/' + this.state.content_list[i]._id + '/edit'}><small>editar</small><br />{this.state.content_list[i].label || this.state.content_list[i].title}</Button>);
            }
        } else {
            for (let i = 0; i < this.state.content_list.length; i++) {
                if (this.state.tag) {
                    content_ui.push(<Content content={this.state.content_list[i]} project_id={this.state.project_id} getData={this.getDataByTag} />);
                } else {
                    content_ui.push(<Content content={this.state.content_list[i]} project_id={this.state.project_id} getData={this.getData} />);
                }
            }
        }

        if (content_ui.length == 0) {
            content_ui.push(<Label>Nenhum conteúdo encontrado</Label>)
        }

        let latest_ui = [];
        for (let i = 0; i < this.state.latest.length; i++) {
            latest_ui.push(<Content content={this.state.latest[i]} project_id={this.state.project_id} getData={this.getDataByTag} />);
        }
        if (latest_ui.length == 0) {
            latest_ui.push(<Label>Nenhuma notícia encontrada</Label>)
        }
        return (
            <div>
                <Segment basic>
                    {this.state.tag == 'home' &&
                        <div>
                            {content_home}
                        </div>
                    }
                    {this.state.tag != 'home' &&
                        <div>
                            {this.state.source == 'search' &&
                                <h3>Resultados da Busca:</h3>
                            }
                            <List divided verticalAlign='middle'>
                                {content_ui}
                            </List>
                            {this.state.source == 'search' && this.state.loadmore_search &&
                                <Button size='mini' onClick={(e) => this.search(this.state.term, this.state.tag, this.state.content_list[this.state.content_list.length - 1].created, false)}>
                                    <Icon name='plus' />
                                    Carregar mais
                                </Button>
                            }
                        </div>
                    }
                    {this.state.tag != 'home' && this.state.source != 'search' && this.state.loadmore &&
                        <div>
                            <Button size='mini' onClick={(e) => this.getDataByTag(this.state.tag, this.state.content_list[this.state.content_list.length - 1].created)}>
                                <Icon name='plus' />
                                Carregar mais
                            </Button>
                        </div>
                    }
                </Segment>
                <br />
                {this.state.tag == 'home' &&
                    <Segment basic>
                        <h5>Últimas notícias criadas</h5>
                        <List divided verticalAlign='middle'>
                            {latest_ui}
                        </List>
                    </Segment>
                }
            </div>
        );
    }
}

export default ProjectDetails;
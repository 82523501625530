import React, { Component } from 'react';
import {
    Link,
    Route,
    Switch
} from 'react-router-dom'
import PubSub from 'pubsub-js';
import { Button, Card, Grid, Header, Icon, Message, Segment } from 'semantic-ui-react'
import * as http from '../../../../http.js';

class ContentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: {},
            content_id: props.match.params.content_id,
            project_id: props.match.params.id,
            ajax_active: false
        };

    }
    componentWillMount() {
        // console.log('props mount');
        // console.log(this.props);
        this.getData(this.state.project_id, this.state.content_id);
    }
    getData(id, content_id) {
        http.get_api('/api/cms/content/' + content_id, (ret) => {
            this.setState({ content: ret.data.content });
        });
    }
    render() {

        return (
            <Segment>
                {this.state.content.title}

                {/* <Route path={"/content/:id/edit"} component={ContentEdit} /> */}
            </Segment>
        );
    }
}

export default ContentDetails;
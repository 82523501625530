import React, { Component } from 'react';
import {
    Link,
    Redirect,
    Route,
    Switch
} from 'react-router-dom'
import PubSub from 'pubsub-js';
import { Header, Icon, Menu, Segment, Sidebar } from 'semantic-ui-react'
import * as store from '../../../store.js';
import * as http from '../../../http.js';

import CompanyHome from './company_home';

class CompanyBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: store.get_object('profile'),
            isOpen: false,
            project: {}
        };
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.getData = this.getData.bind(this);
        // store.remove('current_project');
        PubSub.publish('project_unloaded');
    }
    toggleSidebar() {
        this.setState({
            sidebarOpen: !this.state.sidebarOpen
        });
    }
    componentWillMount() {
        this.getData(this.props.match.params.id);
    }
    getData(id) {
    }
    render() {
        return (
            <div className='fullHeight'>
                <Switch>
                    <Route path={"/cms/company"} component={CompanyHome} />
                </Switch>
            </div>
        );
    }
}
export default CompanyBase;
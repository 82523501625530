import React, { Component } from 'react';
import {
    Link,
    Route,
    Switch
} from 'react-router-dom'
import PubSub from 'pubsub-js';
import { Button, Card, Grid, Header, Icon, Message, Segment } from 'semantic-ui-react'

import ProfileEdit from '../../../components/ProfileEdit';
import * as http from '../../../http.js';
import * as store from '../../../store.js';

const UserCard = (props) => {
    const profile = props.profile;
    if (!profile) return null;
    return (
        <Card>
            <Card.Content>
                <Card.Header>{profile.data.user.first_name} {profile.data.user.last_name}</Card.Header>
            </Card.Content>
            <Card.Content extra>
                <Button.Group fluid size='mini'>
                    <Button basic color='orange'>Excluir</Button>
                    <Button inverted color='blue' onClick={(e) => props.edit(profile)}><Icon name='edit' />Editar</Button>
                </Button.Group>
            </Card.Content>
        </Card>
    );
}

class CompanyHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: {},
            project_id: props.match.params.id,
            profiles: [],
            modalFiles: false
        };
        this.addUser = this.addUser.bind(this);
        this.editUser = this.editUser.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.getData = this.getData.bind(this);
    }
    componentWillMount() {
        this.getData();
    }
    getData() {
        http.get_api('/api/profile/me', (ret) => {
            store.set_object('profile', ret.data.profile);
            this.setState({ profile: ret.data.profile, user: ret.data.user });
        });
        http.get_api('/api/profile/company', (ret) => {
            this.setState({ profiles: ret.data });
        });
    }
    editUser(profile) {
        this.edit_modal.editUser(profile);
        // this.setState({ modal: true,edit_user:user });
    }
    addUser() {
        this.edit_modal.editUser();
    }
    removeUser(profile) {
        if (window.confirm('Deseja excluir o usuário?')) {
            http.delete_api('/api/tasks/user/' + profile._id, (ret) => {
                PubSub.publish('profile_updated');
                // store.set_object('profile', ret.data.profile);
                // this.props.history.push('/tasks');
                this.setState({ modal: false });
            });
        }
        // this.setState({ modal: true,edit_user:user });
    }
    render() {
        let users_ui = [];
        if(this.state.profiles){
            for (let i = 0; i < this.state.profiles.length; i++) {
                if (this.state.profile && this.state.profiles[i] && this.state.profiles[i]._id == this.state.profile._id) continue;
                users_ui.push(<UserCard profile={this.state.profiles[i]} edit={this.editUser} remove={this.removeUser} />);
            }
        }
        return (
            <Segment basic={true}>
                <Header as='h1'>
                    Gerenciar Empresa
                    <Header.Subheader>
                    </Header.Subheader>
                </Header>
                <Segment basic={true}>
                    <Header>
                        Meu Perfil
                    </Header>
                    <UserCard profile={this.state.profile} edit={this.editUser} remove={this.removeUser} />

                </Segment>
                {this.state.profile && this.state.profile.role == 'admin' &&
                    <Segment basic={true}>
                        <Header>
                            Usuários | <Button color='green' inverted size='mini' onClick={this.addUser}>Adicionar Usuário</Button>
                        </Header>
                        <Card.Group>
                            {users_ui}
                        </Card.Group>
                    </Segment>
                }
                <ProfileEdit ref={(child) => { this.edit_modal = child; }} update={this.getData} />
            </Segment >
        );
    }
}

export default CompanyHome;
import React, { Component } from 'react';
import { Router } from 'react-router';
// import { Button, ButtonGroup, Col, Container, Form, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Button, Divider, Form, Grid, Icon, Input, Label, Modal, Segment } from 'semantic-ui-react'
import {
    Link,
    Route
} from 'react-router-dom'
import JsxParser from 'react-jsx-parser'
import PubSub from 'pubsub-js';
import { Async } from 'react-select';
import queryString from 'query-string';
import AceEditor from 'react-ace';
import 'brace/theme/monokai';
import 'brace/mode/html';

import * as http from '../../../../http.js';

// import ComponentCMS from '../../../components/content/component_cms';

class ComponentEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true,
            component: {},
            project_id: props.match.params.id,
            component_id: props.match.params.component_id,
            ajax_active: false,
            modalComponents: false
        };
        this.toggle = this.toggle.bind(this);
        this.toggleComponents = this.toggleComponents.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitClose = this.handleSubmitClose.bind(this);
        this.onChangeBody = this.onChangeBody.bind(this);
        this.onChangeJSX = this.onChangeJSX.bind(this);
        // this.addComponent = this.addComponent.bind(this);
        // this.showAddComponent = this.showAddComponent.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);

    }
    toggle() {
        if (this.state.modal) {
            let path = '/cms/projects/' + this.state.project_id + '/components';
            this.props.history.push(path)
        }
        this.setState({ modal: !this.state.modal });
    }
    toggleComponents() {
        this.setState({ modalComponents: !this.state.modalComponents });
    }
    close() {
        this.setState({ modal: false });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let names = target.name.split('.');
        var prop = this.state;
        var el = this.state[names[0]];
        for (var i in names) {
            if (typeof prop[names[i]] != 'object') break;
            prop = prop[names[i]];
        }
        prop[names[names.length - 1]] = value;
        this.setState({
            [names[0]]: el
        });
    }
    handleElementChange(val, el, key) {
        el = this.state[el];
        el[key] = val;
        this.setState({ el });
    }
    onChangeBody(data) {
        this.state.component.body = data;
    }
    onChangeJSX(data) {
        this.state.component.jsx = data;
    }

    handleSubmit(event, target, cb) {
        event.preventDefault();
        this.setState({ ajax_active: true });
        this.state.component.project = this.state.project_id;
        http.post_api('/api/cms/component', this.state.component, (ret) => {
            PubSub.publish('component_updated');
            this.setState({ ajax_active: false });
            if (cb) cb();
        }, () => {
            this.setState({ ajax_active: false });
            if (cb) cb();
        });
    }
    handleSubmitClose(event, target) {
        this.handleSubmit(event, target, () => {
            this.props.history.push('/cms/projects/' + this.state.project_id + '/components')
        });
    }
    get_data(id) {
        if (id == 'new') {
            this.setState({ component: {} });
        } else {
            http.get_api('/api/cms/component/' + id, (ret) => {
                if (!ret.data.component.project) ret.data.component.project = this.state.project_id;
                this.setState({ component: ret.data.component });
            });
        }
    }
    componentWillMount() {
        this.get_data(this.state.component_id);
    }
    render() {
        // if(!this.state.template)return null;
        class App extends React.Component {
            constructor(props) {
                super(props);
            }
            render() {
                return "<Button>Teste</Button>";
            }
        }

        return (
            <Form onSubmit={this.handleSubmit}>
                <Modal closeOnDimmerClick={false} open={this.state.modal} basic onClose={this.toggle} size='fullscreen'>
                    <Modal.Content>
                        <h1>{this.state.component._id ? 'Editar' : 'Novo'} Componente</h1>
                        <JsxParser
                            components={{ Button }}
                            jsx={'<Button>Teste</Button>'}
                        />
                        <Form.Field>
                            <label>Nome</label>
                            <Form.Input fluid={true} type="text" name="component.name" value={this.state.component.name} id="component.name" onChange={this.handleInputChange} />
                        </Form.Field>
                        <Divider />
                        <Form.Field>
                            <label>HTML</label>
                            <AceEditor
                                ref="aceEditor"
                                mode="html"
                                theme="monokai"
                                highlightActiveLine={true}
                                onChange={this.onChangeBody}
                                name="component_body"
                                editorProps={{ $blockScrolling: true }}
                                value={this.state.component.body}
                                width='100%'
                            />
                        </Form.Field>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button disabled={this.state.ajax_active} color="yellow" inverted size="small" onClick={this.toggle}>Cancel</Button>
                        <Button disabled={this.state.ajax_active} type="submit" inverted color="green" size='large' onClick={this.handleSubmit}>Salvar</Button>
                        <Button disabled={this.state.ajax_active} type="submit" inverted color="green" size='large' onClick={this.handleSubmitClose}>Salvar e Fechar</Button>
                    </Modal.Actions>
                </Modal>

            </Form>
        );
    }
}

export default ComponentEdit;
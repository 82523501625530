import React, { Component } from 'react';
import {
  Link,
  Route,
  Switch
} from 'react-router-dom'

import { Icon, Menu } from 'semantic-ui-react'

import Login from './login';
import Register from './register';
import Home from './home';
import '../assets/style/main.css';

class Base extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
  }

  changeBodyBg = (ev, msg) => {
    // document.body.style.transition = 'all 1000ms ease';
    // document.body.style.backgroundColor = msg;
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    document.body.className = 'bg1';
    return (
      <div className="App fullHeight">
        <div className="AppNav">
          <Menu borderless={true} inverted attached='top'>
            <Menu.Item
              onClick={this.toggleSidebar}
            >
            </Menu.Item>
            <Menu.Item
              as={Link}
              to='/'
            >
              CMS
            </Menu.Item>
            <Menu.Menu position='right'>
              <Menu.Item
                as={Link}
                to='/login'
              >
                Login
            </Menu.Item>
            {/* <Menu.Item
                as={Link}
                to='/cadastrese'
              >
                Cadastre-se
            </Menu.Item> */}
            </Menu.Menu>
          </Menu>

        </div>
        <div className="AppContent fullHeight">
          <Switch>
            <Route path={"/login"} component={Login} />
            <Route path={"/cadastrese"} exact component={Register} />
            <Route path={"/"} exact component={Login} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Base;

import React, { Component } from 'react';
import { Button, Divider, Form, Grid, Icon, Input, Label, Modal, Segment } from 'semantic-ui-react'
import * as http from '../../http.js';

class ComponentCMS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: props.display,
            components: []
        };
        this.addComponent = this.addComponent.bind(this);
        this.getData = this.getData.bind(this);

    }
    componentWillMount() {
        this.getData();
    }
    getData() {
        http.get_api('/api/cms/component/'+this.props.project_id+'/list', (resp) => {
            this.setState({ components: resp.data });
        });
    }
    // Math.random().toString(36).substring(7);
    addComponent(comp) {
        let html = '<'+comp.name + ' id="' + Math.random().toString(36).substring(7) + '"/>';
        // html = html.replace('id=""', 'id="' + Math.random().toString(36).substring(7) + '"');
        this.props.add(html);
    }
    render() {
        let components_ui=[];
        for(let i=0; i < this.state.components.length;i++){
            components_ui.push(<div>{this.state.components[i].name} <Button type='button' inverted color="green" size='mini' onClick={(e)=>{this.addComponent(this.state.components[i])}}>Adicionar</Button></div>);
        }
        return (
            <Modal open={this.props.modal} basic onClose={this.props.toggle} size='small'>
                <Modal.Content>
                    <h1>Adicionar Componente</h1>
                    <div>
                        {components_ui}
                    </div>
                    <div className='actionButtons'>
                        <Button type='button' disabled={this.state.ajax_active} color="yellow" inverted size="small" onClick={this.props.toggle}>Cancelar</Button>
                    </div>
                </Modal.Content>
            </Modal>
        )
    }
}

export default ComponentCMS;
import React, { Component } from 'react';
import { Button, Card, Divider, Dropdown, Form, Grid, Icon, Image, Input, Label, Modal, Popup, Segment } from 'semantic-ui-react'
import FileManager from '../FileManager';
import * as store from '../../store.js';

class Articles extends Component {
    static defaultProps = {
        updateComponent: () => {
            // console.log('Event Handler');
        },
        componentGetData: () => {
            // console.log('Event Handler');
        },
        getProject: () => {
            // console.log('Event Handler');
        },
        getProfile: () => {
            // console.log('Event Handler');
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            modal: props.display,
            modalFiles: false,
            active_item: null,
            data: {},
            tags:[],
            content_tags:[],
            tags_options:[],
            profile:store.get_object('profile')
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTag = this.handleChangeTag.bind(this);
    }
    componentDidMount() {
        let val = this.props.componentGetData({ id: this.props.id });
        let tags = store.get_object('tags');
        if(!tags)tags = [];
        let tags_options = [];
        for (let i = 0; i < tags.length; i++) {
            tags_options.push({ value: tags[i]._id, text: tags[i].name });
        }
        if(!val)val={};
        this.setState({ data: val, tags:tags, tags_options: tags_options, content_tags:tags})
    }
    handleChange(value, key) {
        this.state.data[key] = value
        this.setState({ data: this.state.data });
        this.props.updateComponent({ id: this.props.id,name:'Articles', value: this.state.data });
    }
    handleChangeTag(ev, data) {
        let values = [];
        for (let i = 0; i < data.value.length; i++) {
            for (let j = 0; j < this.state.tags.length; j++) {
                if (this.state.tags[j]._id == data.value[i]) {
                    values.push(data.value[i]);
                    break;
                }
            }
        }
        if(!this.state.data)this.state.data={};
        if (!this.state.data.tags) this.state.data.tags = [];
        this.state.data.tags = values;
        this.setState({ data: this.state.data });
        this.props.updateComponent({ id: this.props.id, name:'Articles', value: this.state.data });
    }
    render() {
        if (this.state.error) {
            return <h1>Caught an error.</h1>
        }
        let tags = [];
        if(this.state.data && this.state.data && this.state.data.tags){
            tags = this.state.data.tags;
        }

        if(this.state.profile && this.state.profile.role!='admin'){
            return null;
        }
        // if((this.state.profile && this.state.profile.role!='admin') && (this.state.content_tags && this.state.content_tags.indexOf('index')>=0)){return null}

        return (
            <Segment>
                <h4>{this.props.name || 'Artigos'}</h4>
                <Grid columns={2}>
                    <Grid.Column>
                        <Form.Input label='Título' fluid value={this.state.data.title} onChange={(e) => { this.handleChange(e.target.value, 'title') }} />
                    </Grid.Column>
                    <Grid.Column>
                        {/* <Form.Input label='Tags' fluid value={this.state.data.tags} onChange={(e) => { this.handleChange(e.target.value, 'tags') }} /> */}
                        <Form.Field>
                            <label>Tags</label>
                            <Dropdown
                                closeOnChange={true}
                                multiple
                                selection
                                fluid
                                search
                                options={this.state.tags_options}
                                placeholder='Escolha as tags'
                                onChange={this.handleChangeTag}
                                value={tags}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid>
            </Segment>
        )
    }
}

export default Articles;
import React, { Component } from 'react';
import { Button, Divider, Form, Grid, Header, Icon, Image, Input, Label, Modal, Popup, Segment, Sticky } from 'semantic-ui-react'
// import SunEditor from 'suneditor-react';
// import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
// import SunEditor, { buttonList } from "suneditor-react";

import { Editor } from '@tinymce/tinymce-react';

import FileManager from '../FileManager';
import * as http from '../../http.js';
import * as store from '../../store.js';

import '../../assets/style/site.css';

// const FileType = require('file-type');

class EditableContent extends Component {
    static defaultProps = {
        updateComponent: () => {
            // console.log('Event Handler');
        },
        componentGetData: () => {
            // console.log('Event Handler');
        },
        getProject: () => {
            // console.log('Event Handler');
        },
        getProfile: () => {
            // console.log('Event Handler');
        }
    }
    constructor(props) {
        super(props);
        const context = this;
        let project = store.get_object('current_project');
        this.state = {
            modal: props.display,
            text: props.text || '',
            project: project,
            imageMenu: false,
            imageSelected: null,
            imageWidth: 0,
            modalUpload: false,
            modalGallery: false,
            showRaw: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.switchImageMenu = this.switchImageMenu.bind(this);
        this.setImageWidth = this.setImageWidth.bind(this);
        this.toggleUpload = this.toggleUpload.bind(this);
        this.handleChangeRaw.bind(this);
        this.handleClickShowRaw = this.handleClickShowRaw.bind(this);
        this.handleImageUpload = this.handleImageUpload.bind(this);
    }

    componentDidMount() {
        let val = this.props.componentGetData({ id: this.props.id });
        if (val && val._id) val = val.content_data;
        if (!val) val = '';
        this.setState({ text: val });
    }

    handleChange(content) {
        this.props.updateComponent({ id: this.props.id, name: 'EditableContent', value: content });
        // this.setState({ text: content });
        return true;
    }
    switchImageMenu(display) {
        let el = { imageMenu: display };
        el.imageSelected = this.state.imageSelected;
        if (this.state.imageSelected && display) {
            el.imageSelected.domNode.className = 'selected';
        } else if (this.state.imageSelected && this.state.imageSelected.domNode) {
            el.imageSelected.domNode.className = '';
        }
        this.setState(el);
    }
    setImageWidth(e) {
        this.state.imageWidth = e.target.value;
        this.state.imageSelected.domNode.width = e.target.value;
        this.setState({ imageWidth: this.state.imageWidth });
    }
    toggleUpload() {
        this.setState({ modalUpload: !this.state.modalUpload });
    }
    toggleModalGallery() {
            this.setState({ modalGallery: !this.state.modalGallery });
        }
        // addImage(url) {
        //     url = this.state.project.simpleConnector.destination + url;
        //     let selection = this.refs.editor.getEditor().getSelection();
        //     let cursorPosition = 0;
        //     if (selection) {
        //         cursorPosition = selection.index;
        //     }
        //     let el = this.refs.editor.getEditor().insertEmbed(cursorPosition, 'image', url);
        //     var tempCont = document.createElement("div");
        //     new Quill(tempCont).setContents(this.refs.editor.getEditor().getContents());
        //     let html = tempCont.getElementsByClassName("ql-editor")[0].innerHTML;
        //     this.handleChange(html, null, 'user');
        //     this.toggleUpload();
        // }
    onDrop(files) {
        this.setState({
            files
        });
        http.upload_api('/api/cms/files/' + this.props.getProject() + '/upload?path=img/', files, (ret) => {
            if (!ret.data.files) return;
            for (let i = 0; i < ret.data.files.length; i++) {
                this.addImage(ret.data.files[i]);
            }
        });
    }
    handleMouseMove(ev) {
        this.state.mouseY = ev.clientY;
    }
    handleChangeRaw(html) {
        this.setState({ rawHtml: html })
    }
    handleClickShowRaw() {
        const isEditingRaw = this.state.showRaw;
        this.setState({ showRaw: !isEditingRaw })
        this.syncViews(isEditingRaw)
    }
    syncViews(fromRaw) {
        if (fromRaw) this.setState({ text: this.state.rawHtml })
        else this.setState({ rawHtml: this.state.text })
    }

    multipartFormData(image) {
        var boundary = '----' + (new Date()).getTime();
        var bodyString = [];
        bodyString.push(
            '--' + boundary,
            'Content-Disposition: form-data; name="' + "file" + '";' + 'filename="' + "my_file.jpg" + '"',
            'Content-Type: ' + "image/jpeg",
            'Content-Transfer-Encoding: base64', '', //need /r/n twice here
            image.substring(23) //remove the data:image/jpeg;base64,
        );

        bodyString.push('--' + boundary + '--', '');
        var content = bodyString.join('\r\n');
        return {
            content: content,
            headers: {
                'Content-Type': 'multipart/form-data; boundary=' + boundary,
                'Content-Length': content.length
            }
        }
    }
    // dataURLtoFile(dataurl, filename) {
    //     if (!dataurl || dataurl.split(',').length < 2) return;
    //     var arr = dataurl.split(','),
    //         mime = arr[0].match(/:(.*?);/)[1],
    //         bstr = atob(arr[1]),
    //         n = bstr.length,
    //         u8arr = new Uint8Array(n);
    //     while (n--) {
    //         u8arr[n] = bstr.charCodeAt(n);
    //     }
    //     return new File([u8arr], filename, { type: mime });
    // }
    dataURLtoFile(imageInfo) {
        if (!imageInfo) return;

        // let imgBuff = Buffer.from(imageInfo.blobUri(), 'base64');
        let filename = imageInfo.filename();
        let mime = imageInfo.blob().type;
        let bstr = atob(imageInfo.base64());
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    // handleImageUpload(targetImgElement, index, state, imageInfo, remainingFilesCount) {
    //     if (!imageInfo) return;
    //     // let img = Buffer.from(imageInfo.src, 'base64')
    //     // let img = this.dataURItoBlob(imageInfo);
    //     let file = this.dataURLtoFile(imageInfo.src, imageInfo.name);
    //     // let file = new File([img], imageInfo.name, { type: imageInfo.src.split(';')[0].split(':')[1] });
    //     http.upload_api('/api/cms/files/' + this.props.getProject() + '/upload-sync?path=img/', [file], (ret) => {
    //         console.log('Uploaded');
    //         console.log(ret.data);
    //         if (ret.data.files && ret.data.files.length > 0) {
    //             targetImgElement.src = ret.data.files[0];
    //         }
    //     });
    //     // imageInfo.src = ''
    //     // targetImgElement.src='https://harness.io/images/bird-lrg.png';
    // }

    handleImageUpload(imageInfo, success, failure) {
        if (!imageInfo) return;

        let file = this.dataURLtoFile(imageInfo);
        // let img = new Image();    

        http.upload_api('/api/cms/files/' + this.props.getProject() + '/upload-sync?path=childhood/imagens/', [file], (ret) => {
            if (ret.data.files && ret.data.files.length > 0) {
                console.log("file: ", ret.data.files)
                // img.src = ret.data.files[0];
                // console.log("dim: ", i/mg.width)

                success(ret.data.files[0]);
            } else {
                failure();
            }
        });
    }

    render() {
        if (this.state.error) {
            return <h1> Caught an error. </h1>
        }

        return ( 
            <Segment style = {{'margin':'auto', 'min-width':'880px' }}>
                <h4 > Conteúdo Editável < /h4> 
                <Editor
                     initialValue={this.state.text}
                     apiKey="4w61vtzuy3s1u6ma0l33lu8sllz4cp8iu4m200y4gbboftmh"
                     init={{
                        language: 'pt_BR',
                        height: 500,
                        menubar: true,
                        style_formats: [
                              {
                                  title: 'Espaçamento de Linha',
                                  items: [
                                    {
                                      title: `Default`,
                                      inline: 'span',
                                      styles: { 'line-height': `normal`, display: 'inline-block' }
                                    },
                                    {
                                      title: `0px`,
                                      inline: 'span',
                                      styles: { 'line-height': `0px`, display: 'inline-block' }
                                    },
                                    {
                                      title: `8px`,
                                      inline: 'span',
                                      styles: { 'line-height': `8px`, display: 'inline-block' }
                                    },
                                    {
                                      title: `10px`,
                                      inline: 'span',
                                      styles: { 'line-height': `10px`, display: 'inline-block' }
                                    },
                                    {
                                      title: `11px`,
                                      inline: 'span',
                                      styles: { 'line-height': `11px`, display: 'inline-block' }
                                    },
                                    {
                                      title: `12px`,
                                      inline: 'span',
                                      styles: { 'line-height': `12px`, display: 'inline-block' }
                                    },
                                    {
                                      title: `13px`,
                                      inline: 'span',
                                      styles: { 'line-height': `13px`, display: 'inline-block' }
                                    },
                                    {
                                      title: `14px`,
                                      inline: 'span',
                                      styles: { 'line-height': `14px`, display: 'inline-block' }
                                    },
                                    {
                                      title: `15px`,
                                      inline: 'span',
                                      styles: { 'line-height': `15px`, display: 'inline-block' }
                                    },
                                    {
                                      title: `16px`,
                                      inline: 'span',
                                      styles: { 'line-height': `16px`, display: 'inline-block' }
                                    },
                                    {
                                      title: `17px`,
                                      inline: 'span',
                                      styles: { 'line-height': `17px`, display: 'inline-block' }
                                    },
                                    {
                                      title: `18px`,
                                      inline: 'span',
                                      styles: { 'line-height': `18px`, display: 'inline-block' }
                                    },
                                    {
                                      title: `19px`,
                                      inline: 'span',
                                      styles: { 'line-height': `19px`, display: 'inline-block' }
                                    },
                                    {
                                      title: `20px`,
                                      inline: 'span',
                                      styles: { 'line-height': `20px`, display: 'inline-block' }
                                    },
                                    {
                                      title: `23px`,
                                      inline: 'span',
                                      styles: { 'line-height': `23px`, display: 'inline-block' }
                                    },
                                    {
                                      title: `25px`,
                                      inline: 'span',
                                      styles: { 'line-height': `25px`, display: 'inline-block' }
                                    },
                                    {
                                      title: `30px`,
                                      inline: 'span',
                                      styles: { 'line-height': `30px`, display: 'inline-block' }
                                    },
                                    {
                                      title: `40px`,
                                      inline: 'span',
                                      styles: { 'line-height': `40px`, display: 'inline-block' }
                                    }
                                  ]
                              }
                          ],  
                        plugins: [
                         'advlist autolink lists link image charmap print preview anchor',
                         'searchreplace visualblocks code fullscreen',
                         'insertdatetime media table paste code help wordcount media'
                        ],
                        toolbar:
                         'undo redo | formatselect | fontsizeselect | bold italic forecolor backcolor | \
                         alignleft aligncenter alignright alignjustify | \
                         bullist numlist outdent indent | removeformat | link media  table  image| code | help',

                        // without images_upload_url set, Upload tab won't show up
                        images_upload_url: 'postAcceptor.php',
                        // we override default upload handler to simulate successful upload
                        images_upload_handler: (blobInfo, success, failure) => {
                            this.handleImageUpload(blobInfo, success, failure);
                        },
                        image_caption: true,
                        image_description: false,
                        table_style_by_css: true,
                        table_default_styles: {
                            'text-align': 'left !important',
                            'font-weight': '300 !important'
                        },
                        content_css: "css/tiny.css",
                        file_picker_types: 'image',
                        content_style: 'img {max-width: 100%;height:auto;}' + '.visao-desktop {display:none}',
                        image_advtab: true,
                        object_resizing: false,
                        image_class_list: [
                            {title: 'Imagem Responsiva', value: 'img-fluid'},
                        ],
                         media_url_resolver: function (data, resolve/*, reject*/) {
                          console.log("video data media url: ", data);
                            var embedHtml = '<div class="visao-mobile" style="position: relative;padding-bottom: 56.25%;padding-top: 35px;height: 0;overflow: hidden;"><iframe src="' + data.url +
                            '" width="500" height="300" style="position: absolute;top:0;left: 0;width: 100%;height: 100%;" ></iframe></div><div class="visao-desktop"><iframe src="' + data.url +
                            '" width="560" height="315"></iframe></div>';
                            resolve({html: embedHtml});
                        }
                     }}
                     onEditorChange={this.handleChange}
                   />
            </Segment>
        )
    }
}

export default EditableContent;
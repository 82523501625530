import React, { Component } from 'react';
import { Button, Card, Form, Grid, Icon, Input, Message, Modal, Segment } from 'semantic-ui-react'
import {
  Link,
  Route
} from 'react-router-dom'
import PubSub from 'pubsub-js';
import * as http from '../../../http.js';
import ProjectEdit from './project_edit.js';

const Project = (props) => {
  const project = props.project;
  let ajax_active = false;
  let removeProject = (project) => {
    if (window.confirm('Deseja realmente excluir projeto?')) {
      ajax_active = true;
      http.delete_api('/api/cms/project/' + project._id, (resp) => {
        PubSub.publish('project_updated');
        ajax_active = false;
      }, () => { ajax_active = false; });
    }
  }
  if (!project) return null;
  return (
    <Card>
      <Card.Content>
        <Card.Header>
          <Link to={'/cms/projects/' + project._id}>
            {project.name}
          </Link>
        </Card.Header>
        {project.client &&
          <small>{project.client.name}</small>
        }
      </Card.Content>
      <Card.Content extra>
        <Button as={Link} to={'/cms/projects/edit/' + project._id} size="mini" inverted color='blue'>
          <Icon name='edit' /> Editar
        </Button>
        <Button size="mini" inverted color='orange' disabled={ajax_active} onClick={(e) => { e.stopPropagation(); removeProject(project) }} title="Remover">
          <Icon name='trash' />
          Excluir
        </Button>
      </Card.Content>
    </Card >
  );
}

const Projects = (props) => {
  const projects = props.projects.map((project) =>
    <Project project={project} key={project._id}></Project>
  );
  return (
    <Card.Group>{projects}</Card.Group>
  );
}

class ProjectsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: []
    };
    this.getData = this.getData.bind(this);
    PubSub.subscribe('project_updated', () => {
      this.getData();
    });
  }
  componentWillMount() {
    this.getData();
    PubSub.publish('project_unloaded');
  }
  getData() {
    http.get_api('/api/cms/project/list', (resp) => {
      if(resp.data && resp.data.length==1){
        this.props.history.push('/cms/projects/'+resp.data[0]._id+'/tag/home');
        return;
      }
      this.setState({ projects: resp.data });
    });
  }

  render() {
    return (
      <Segment basic={true}>
        <h1>
          Projetos &nbsp;
            <Link to={'/cms/projects/edit/new'}>
            <Button color="olive" inverted size="small">Novo Projeto</Button>
          </Link>
        </h1>
        {(!this.state.projects || this.state.projects.length == 0) &&
          <Message color="grey">Nenhum projeto cadastrado, adicione um agora</Message>
        }
        <Projects projects={this.state.projects}></Projects>
        <Route path={"/cms/projects/edit/:id"} component={ProjectEdit} />
      </Segment>
    );
  }
}

export default ProjectsList;